import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ServiceCharges from "./serviceCharges/index";
import InVoiceDetail from "./InVoiceDetail";
import CreditCard from "./CreditCard";
import { userSelector } from "../../store/slices/userSlice";
import BillingSidebar from "../../layouts/BillingSideBar";
import BillingWrapper from "../../containers/BillingWrapper";

export default function Billing() {
  const navigate = useNavigate();
  const userDetails = useSelector(userSelector);
  const { permissions } = userDetails
  const { path } = useParams();
  const key = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
  const stripePromise = loadStripe(key);

  useEffect(() => {
    if (permissions?.billing && !permissions?.billing?.all) {
      navigate("/404");
    }
  }, [permissions])

  return (
    <>
      <Grid container item height="100%" width="100%" spacing={3} direction="row">
        <Grid
          container
          item
          xs={12}
          sm={3}
          md={2}
          lg={2}
          width="100%"
          justifyContent="start"
          alignItems="start"
          direction="column"
        >
          <BillingSidebar {...{ path }} />
        </Grid>
        {path === "services" && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={8}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="column"
          >
            <BillingWrapper title={"Service Charges"}>
              <ServiceCharges />
            </BillingWrapper>
          </Grid>
        )}
        {path === "transactions" && userDetails?.is_billing_access && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={10}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="row"
          >
            <BillingWrapper title={"Transactions"}>
              <InVoiceDetail />
            </BillingWrapper>
          </Grid>
        )}
        {path === "creditCard" && userDetails?.is_billing_access && (
          <Grid
            container
            item
            xs={12}
            sm={9}
            md={9}
            lg={10}
            width="100%"
            justifyContent="start"
            alignItems="start"
            direction="row"
          >
            <BillingWrapper title={"Credit Card"}>
              <Elements stripe={stripePromise}>
                <CreditCard />
              </Elements>
            </BillingWrapper>
          </Grid>
        )}
      </Grid>
    </>
  );
}
