import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { passwordLessLogin, userSelector } from '../../store/slices/userSlice';

const Auth0Login = () => {
    const { isLoading, isAuthenticated, user, loginWithRedirect, handleRedirectCallback } = useAuth0();
    const { passwordLessLoginSuccess, isPasswordLessLoginNewUser } = useSelector(userSelector);
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    console.log('isAuthenticated :>> ', isAuthenticated);
    console.log('user :>> ', user);
    console.log('tokenExpired :>> ', token);

    useEffect(async () => {
        try {
            if (!isAuthenticated && user === undefined) {
                const query = window.location.search;
                if (query.includes("code=") && query.includes("state=")) {
                    console.log(":>>> Parsing redirect");
                    try {
                        // eslint-disable-next-line no-unused-vars
                        const result = await handleRedirectCallback();
                        console.log("Logged in!");
                    } catch (err) {
                        console.log("Error parsing redirect:", err);
                    }
                    window.history.replaceState({}, document.title, "/auth0-login");
                } else {
                    loginWithRedirect({ redirect_uri: `${window.location.origin}/auth0-login` });
                }
            }
        } catch (err) {
            console.log("Log in failed", err);
        }
    }, [isAuthenticated]);

    useEffect(async () => {
        if (user !== undefined && isAuthenticated) {
            await dispatch(passwordLessLogin(user)).unwrap();
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (passwordLessLoginSuccess) {
            if (isPasswordLessLoginNewUser) navigate("/application");
            if (!isPasswordLessLoginNewUser) {
                navigate("/applications");
                localStorage.removeItem("tokenExpired");
            }
        }
    }, [passwordLessLoginSuccess]);


    return (
        !isAuthenticated && isLoading && <Stack
            spacing={2}
            style={{
                background: "rgba(0,0,0, 0.5)",
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                color: "#fff",
            }}
        >
            <CircularProgress />
            <Typography>Please wait...</Typography>
        </Stack>
    );
}

export default Auth0Login