import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { List, ListItem, ListItemButton, ListItemText, Box } from "@mui/material";
import { userType } from "../constants";
import { userSelector } from "../store/slices/userSlice";

export default function SettingsSidebar({ path }) {
  const navigate = useNavigate();
  const userState = useSelector(userSelector);
  // const primaryTypographyProps = {
  //   // color: 'primary',
  //   fontWeight: "400",
  //   fontSize: "13px",
  //   // variant: 'body2',
  // };
  return (
    <>
      <Box sx={{ width: "100%", fontSize: "10px" }}>
        <List>
          <ListItem disablePadding dense>
            <ListItemButton selected={path === "general"} onClick={() => navigate("/settings/general")}>
              <ListItemText
                primary="Profile"
                primaryTypographyProps={{
                  fontSize: "13px",
                  fontWeight: path === "general" ? "700" : 400,
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding dense>
            <ListItemButton selected={path === "lenders"} onClick={() => navigate("/settings/lenders")}>
              <ListItemText
                primary="Lenders"
                primaryTypographyProps={{
                  fontSize: "13px",
                  fontWeight: path === "lenders" ? "700" : 400,
                }}
              />
            </ListItemButton>
          </ListItem>
          {[userType.owner, userType.master].includes(userState?.userType) && (
            <>
              {/* <ListItem disablePadding dense>
                <ListItemButton selected={path === "userRolesList"} onClick={() => navigate("/settings/user")}>
                  <ListItemText
                    primary="User"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: path === "User" ? 700 : 400,
                    }}
                  />
                </ListItemButton>
              </ListItem> */}
              <ListItem disablePadding dense>
                <ListItemButton
                  selected={path === "rolepermissions"}
                  onClick={() => navigate("/settings/rolepermissions")}
                >
                  <ListItemText
                    primary="Role & Permissions"
                    primaryTypographyProps={{
                      fontSize: "13px",
                      fontWeight: path === "rolepermissions" ? 700 : 400,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
      </Box>
    </>
  );
}
