import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { resetPassword, userSelector } from "../../store/slices/userSlice";
import regex from "../../utils/regex";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { key } = useParams();
  const user = useSelector(userSelector);

  const [isSame, setIsSame] = useState(true);
  const [errorField, setErrorField] = useState({ newPassword: "", confirmNewPassword: "" });
  const [allValues, setAllValues] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });

  const handle = {
    onChangeFn: (value, name) => {
      if (name === "newPassword" || name === "confirmNewPassword") {
        if (regex.password.test(value)) setErrorField({ ...errorField, [name]: "" });
        else
          setErrorField({
            ...errorField,
            [name]:
              "Password must contain an uppercase letter, a number, a special character and be 8 or more characters in length.",
          });
      }
      setAllValues({
        ...allValues,
        [name]: value,
      });
    },
    requestResetPassword: async () => {
      if (allValues?.confirmNewPassword !== allValues?.newPassword) {
        setErrorField({ ...errorField, confirmNewPassword: "Passwords do not match." });
        setIsSame(true);
        return;
      }

      let res = await dispatch(resetPassword({ newPassword: allValues?.newPassword, key }));
      if (res?.data?.token) navigate("/applications");
    },
  };

  useEffect(() => {
    if (allValues?.newPassword !== "" && allValues?.confirmNewPassword !== "") {
      setIsSame(false);
    }
  }, [allValues]);

  useEffect(() => {
    if (user?._id) navigate("/applications");
  }, [user]);

  return (
    <Grid
      container
      item
      height="calc(100vh - 64px)"
      width="100%"
      justifyContent="center"
      alignItems="center"
      direction="row-reverse"
      marginTop="-64px"
    >
      <Grid
        container
        item
        xs={12}
        sm={6}
        justifyContent="center"
        alignItems="center"
        direction="column"
        style={{ padding: "100px" }}
      >
        <Typography
          variant="h4"
          style={{
            letterSpacing: "-1.8px",
            fontWeight: 800,
            marginBottom: "10px",
          }}
        >
          Reset Password
        </Typography>

        {/* <Typography fontWeight="400" style={{ marginBottom: "10px" }}>
          Sign into your account below.
        </Typography> */}

        <TextField
          fullWidth
          type="password"
          name="newPassword"
          label="New Password"
          value={allValues?.newPassword}
          margin="dense"
          onChange={(event) => handle.onChangeFn(event.target.value, "newPassword")}
          error={errorField?.newPassword}
          helperText={errorField?.newPassword}
        />
        <TextField
          fullWidth
          type="password"
          name="confirmNewPassword"
          label="Confirm Password"
          value={allValues?.confirmNewPassword}
          margin="dense"
          onChange={(event) => handle.onChangeFn(event.target.value, "confirmNewPassword")}
          error={errorField?.confirmNewPassword}
          helperText={errorField?.confirmNewPassword}
        />
        <LoadingButton
          fullWidth
          loading={""}
          loadingPosition="end"
          variant="contained"
          disabled={isSame}
          onClick={() => handle.requestResetPassword()}
          style={{ marginTop: "20px" }}
        >
          Submit
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
