import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import _ from "lodash";
import { permissionName, servicesName, setLenderCase } from "../../../components/Utils/helperFunction";
import { ERRORS_MESSAGE, STATUS_CODE, tabList } from "../../../constants";
import { getRoleByUserId, saveUserRolePermissions } from "../../../store/slices/userSlice";

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, width: "100%" }}>{children}</Box>}
    </div>
  );
};

export function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const Permission = ({ permissionInfo, setPermissionInfo }) => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [tab, setTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [applicationAccess, setApplicationAccess] = useState("self");
  const [userType, setUserType] = useState("");
  const [applicationAccesses, setApplicationAccesses] = useState({
    create: true,
    edit: true,
    submit: true,
    status_change: true,
    internal_submit: true,
    pre_supplier_data: true,
    quote_create: false,
    quote_edit: false,
    quote_convert: false,
  });

  const [userSettingsAccess, setUserSettingsAccess] = useState(false);
  const [userAccess, setUserAccess] = useState("own");
  const [userAccesses, setUserAccesses] = useState({
    invite: true,
    remove: true,
    edit_role: true,
    status_change: true,
    permission_edit: true,
  });

  const [servicesAccess, setServicesAccess] = useState({
    All: true,
    bank_statements: true,
    asic_extract: true,
    asic_extract_ppsr: true,
    id_verification: true,
    blug_flag: true,
    blug_flag_ppsr: true,
    equifax_basic_report: true,
    equifax_commercial_report: true,
    equifax_with_onescore_report: true,
    equifax_scored_commercial_report: true,
  });
  const [organisationAccess, setOrganisationAccess] = useState({
    edit: true,
    create: true,
    view: true,
    permission_edit: true,
  });
  const all = "All";
  const allLenders = "All";
  const [lendersAccess, setLendersAccess] = useState({
    All: true,
    affordable_car_loans: true,
    alex_bank: true,
    ammf: true,
    australian_premier_finance: true,
    autopay: true,
    branded: true,
    car_start: true,
    finance_one: true,
    firstmac: true,
    latitude: true,
    gedda_money: true,
    greenlight: true,
    liberty: true,
    macquarie: true,
    money3: true,
    moneyplace: true,
    now: true,
    pepper: true,
    plenti: true,
    salt_and_lime: true,
    wisr: true,
    afs: true,
    angle_finance: true,
    anz: true,
    azora_asset_finance: true,
    capital_finance: true,
    dynamoney: true,
    flexi: true,
    group_and_general_finance: true,
    grenke: true,
    metro: true,
    resimac: true,
    scotpac: true,
    selfco: true,
    shift: true,
    westpac: true,
    volkswagen_financial_services: true,
  });

  useEffect(() => {
    if (userId) {
      getUserPermission(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (permissionInfo) {
      setOrganisationAccess(permissionInfo?.organisationAccess);
      setLendersAccess(permissionInfo?.lendersAccess);
      setServicesAccess(permissionInfo?.servicesAccess);
      setUserAccesses(permissionInfo?.userAccesses);
      setUserAccess(permissionInfo?.userAccess);
      setApplicationAccesses(permissionInfo?.applicationAccesses);
      setApplicationAccess(permissionInfo?.applicationAccess);
      setUserSettingsAccess(permissionInfo?.userSettingsAccess);
    }
  }, [permissionInfo]);

  const getUserPermission = async (userId) => {
    await dispatch(getRoleByUserId({ _id: userId })).then(({ payload }) => {
      const updatedServicesAccess = { ...servicesAccess, ...payload?.data?.services };
      const isAllChecked = Object.values(payload?.data?.services).every((value) => value);
      updatedServicesAccess["All"] = isAllChecked;

      const updatedLendersAccess = { ...lendersAccess, ...payload?.data?.lenders };
      const isAllLendersChecked = Object.values(payload?.data?.lenders).every((value) => value);
      updatedLendersAccess["All"] = isAllLendersChecked;
      const { access, ...withoutAccessApplicationAccess } = payload?.data?.application;
      const newUserAccessList = Object.fromEntries(
        Object.entries(payload?.data?.user).filter(([key]) => key !== "access"),
      );
      setApplicationAccesses(withoutAccessApplicationAccess);
      setUserType(payload?.data?.userType);
      setApplicationAccess(payload?.data?.application?.access);
      setUserAccesses(newUserAccessList);
      setUserSettingsAccess(payload?.data?.billing?.all || false);
      setUserAccess(payload?.data?.user?.access);
      setServicesAccess(updatedServicesAccess);
      setLendersAccess(updatedLendersAccess);
      setOrganisationAccess(payload?.data?.org);
    });
  };

  const handle = {
    applicationAccess: (event) => {
      const { name, value, checked } = event.target;
      if (name === "access") {
        setApplicationAccess(value);
        if (permissionInfo) setPermissionInfo({ ...permissionInfo, applicationAccess: value });
        return;
      } else {
        setApplicationAccesses({ ...applicationAccesses, [name]: checked });
        if (permissionInfo)
          setPermissionInfo({ ...permissionInfo, applicationAccesses: { ...applicationAccesses, [name]: checked } });
        return;
      }
    },
    organisationAccess: (event) => {
      const { name, checked } = event.target;
      const updatedOrganisationAccess = { ...organisationAccess, [name]: checked };
      setOrganisationAccess(updatedOrganisationAccess);
      if (permissionInfo) setPermissionInfo({ ...permissionInfo, organisationAccess: updatedOrganisationAccess });
    },
    lendersAccess: (event) => {
      const { checked, name } = event.target;
      if (name === allLenders) {
        const allCheckedLenders = Object.fromEntries(
          Object.entries(lendersAccess).map(([key, value]) => [key, checked]),
        );
        setLendersAccess(allCheckedLenders);
        if (permissionInfo) setPermissionInfo({ ...permissionInfo, lendersAccess: allCheckedLenders });
      } else {
        const updatedLendersAccess = { ...lendersAccess, [name]: checked };
        const { All, ...UpdateLenders } = { ...updatedLendersAccess };
        const isAllChecked = Object.values(UpdateLenders).every((value) => value);
        updatedLendersAccess[allLenders] = isAllChecked;
        setLendersAccess(updatedLendersAccess);
        if (permissionInfo) setPermissionInfo({ ...permissionInfo, lendersAccess: updatedLendersAccess });
      }
    },
    servicesAccess: (event) => {
      const { checked, name } = event.target;
      if (name === all) {
        const allCheckedService = Object.fromEntries(
          Object.entries(servicesAccess).map(([key, value]) => [key, checked]),
        );
        setServicesAccess(allCheckedService);
        if (permissionInfo) setPermissionInfo({ ...permissionInfo, servicesAccess: allCheckedService });
      } else {
        const updatedServicesAccess = { ...servicesAccess, [name]: checked };
        const { All, ...UpdateServices } = { ...updatedServicesAccess };
        const isAllChecked = Object.values(UpdateServices).every((value) => value);
        updatedServicesAccess[all] = isAllChecked;
        setServicesAccess(updatedServicesAccess);
        if (permissionInfo) setPermissionInfo({ ...permissionInfo, servicesAccess: updatedServicesAccess });
      }
    },
    // userSettingsAccess: (event) => {
    //   const { checked } = event.target;
    //   setUserSettingsAccess(checked);
    // },
    userAccess: (event) => {
      const { name, value, checked } = event.target;
      if (name === "access") {
        setUserAccess(value);
        if (permissionInfo) setPermissionInfo({ ...permissionInfo, userAccess: value });
        return;
      } else {
        setUserAccesses({ ...userAccesses, [name]: checked });
        if (permissionInfo)
          setPermissionInfo({ ...permissionInfo, userAccesses: { ...userAccesses, [name]: checked } });
        return;
      }
    },
    saveUserPermission: () => {
      const { All, ...UpdateServicesAccess } = { ...servicesAccess };
      const { AllLenders, ...UpdateLendersAccess } = { ...lendersAccess };
      const allApplicationAccess = {
        ...applicationAccesses,
        access: applicationAccess,
      };
      const allUserAccess = { ...userAccesses, access: userAccess };
      const data = {
        data: {
          permission: {
            application: allApplicationAccess,
            billing: { all: userSettingsAccess },
            services: UpdateServicesAccess,
            lenders: UpdateLendersAccess,
            user: allUserAccess,
            org:organisationAccess,
          },
          userType: userType,
        },
        id: userId,
      };

      dispatch(saveUserRolePermissions(data)).then(({ payload }) => {
        if (payload?.status_code === STATUS_CODE.success) {
          enqueueSnackbar(payload?.status_message, {
            variant: "success",
            autoHideDuration: 5000,
          });
          navigate("/users");
        } else {
          enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
  };

  const permissionTabs = (tab) => {
    switch (tab) {
      case "Applications":
        return (
          <FormControl>
            <Stack direction={"row"} columnGap={1} justifyContent={"center"} alignItems={"center"}>
              <Typography fontSize={"1rem"} color={"rgba(0, 0, 0, 0.6)"}>
                <FormLabel>{permissionName("view")}</FormLabel>
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                fullWidth
                name="access"
                value={applicationAccess || ""}
                onChange={handle?.applicationAccess}
              >
                {["all", "self", "own"].map((item) => (
                  <MenuItem value={item} key={item}>
                    {_.startCase(item)}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {Object.entries(applicationAccesses)?.map(([key, value], index) => (
              <Stack justifyContent={"space-between"} alignItems={"center"} flexDirection={"row"}>
                <FormLabel>{permissionName(key)}</FormLabel>
                <Switch name={key} checked={value} onChange={handle?.applicationAccess} />
              </Stack>
            ))}
          </FormControl>
        );

      case "Users":
        return (
          <FormControl>
            <Stack direction={"row"} columnGap={1} justifyContent={"center"} alignItems={"center"}>
              <Typography fontSize={"1rem"} color={"rgba(0, 0, 0, 0.6)"}>
                <FormLabel>{permissionName("view")}</FormLabel>
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                size="small"
                fullWidth
                name="access"
                value={userAccess || ""}
                onChange={handle?.userAccess}
              >
                {["all", "none", "own"].map((item) => (
                  <MenuItem value={item} key={item}>
                    {_.startCase(item)}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            {Object.entries(userAccesses)?.map(([key, value], index) => (
              <Stack justifyContent={"space-between"} alignItems={"center"} flexDirection={"row"}>
                <FormLabel>{permissionName(key)}</FormLabel>
                <Switch name={key} checked={value} onChange={handle?.userAccess} />
              </Stack>
            ))}
          </FormControl>
        );

      case "Services":
        return (
          <FormControl>
            {Object.entries(servicesAccess)?.map(([key, value], index) => (
              <Stack justifyContent={"space-between"} alignItems={"center"} flexDirection={"row"}>
                <FormLabel>{servicesName(key)}</FormLabel>
                <Switch name={key} checked={value} onChange={handle?.servicesAccess} />
              </Stack>
            ))}
          </FormControl>
        );

      case "organisations":
        return (
          <FormControl>
            {Object.entries(organisationAccess)?.map(([key, value], index) => (
              <Stack justifyContent={"space-between"} alignItems={"center"} flexDirection={"row"}>
                <FormLabel>{permissionName(key)}</FormLabel>
                <Switch name={key} checked={value} onChange={handle?.organisationAccess} />
              </Stack>
            ))}
          </FormControl>
        );

      // case "User Setting":
      //   return (
      //     <FormControl>
      //       <Stack columnGap={1} justifyContent={"center"} alignItems={"center"}>
      //         <FormLabel fontWeight={"bold"} fontSize={"0.75rem"}>
      //           Billing visibility
      //         </FormLabel>
      //         <Switch name="billing" onChange={handle.userSettingsAccess} checked={userSettingsAccess || false} />
      //       </Stack>
      //     </FormControl>
      //   );

      case "Lenders":
        return (
          <FormControl>
            {Object.entries(lendersAccess)?.map(([key, value], index) => (
              <Stack justifyContent={"space-between"} alignItems={"center"} flexDirection={"row"}>
                <FormLabel>{setLenderCase(key)}</FormLabel>
                <Switch name={key} checked={value} onChange={handle?.lendersAccess} />
              </Stack>
            ))}
          </FormControl>
        );
      default:
        return;
    }
  };

  return (
    <Stack sx={{ width: "100%" }}>
      {userId && (
        <Stack flexDirection={"row"}>
          <Typography
            variant="h1"
            style={{
              fontSize: "24px",
              fontWeight: 800,
              letterSpacing: "-0.5px",
            }}
            mb={"10px"}
          >
            User Role & Permissions
          </Typography>
          <Button sx={{ marginLeft: "80px" }} variant="outlined" onClick={handle.saveUserPermission}>
            Save
          </Button>
        </Stack>
      )}
      <Stack flexDirection={"row"}>
        <Tabs orientation="vertical" onChange={(event, newValue) => setTab(newValue)} value={tab}>
          {tabList.map((tabInfo, i) => (
            <Tab label={tabInfo} key={tabInfo} {...a11yProps(i)} />
          ))}
        </Tabs>
        {tabList.map((tabInfo, i) => (
          <TabPanel value={tab} index={i}>
            {permissionTabs(tabInfo)}
          </TabPanel>
        ))}
      </Stack>
    </Stack>
  );
};
