import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PhoneNumber from "./PhoneNumber";
import Email from "./Email";
import ManualAddress from "./ManualAddress";
import { addAddress, deleteAddress, updateAddress, userDetailsSelector, saveValue } from "../../store/slices/applicationFormSlice";
import { fieldRequiredMessage } from "../../constants";
import GoogleAddressSearch from "../Utils/GoogleAddressSearch";
import { userPermissionsSelector } from "../../store/slices/userSlice";
import { customerSelector } from "../../store/slices/customerSlice";

const useStyles = makeStyles((theme) => ({
  helperText: {
    bottom: "-25px",
    position: "absolute",
    color: "#d32f2f",
    [theme.breakpoints.up("md")]: {
      bottom: "-40px",
    },
    [theme.breakpoints.up("lg")]: {
      bottom: "-25px",
      marginLeft: "5px",
      marginRight: "5px",
    },
  },
}));

const CustomerContactDetails = React.memo(
  ({ customer, fieldErrors = {}, setFieldErrors, customerId, applicationId }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { isManualAddress, gettingAddressDetails } = useSelector(userDetailsSelector);

    const permissions = useSelector(userPermissionsSelector);
    const { isCustomerLogin } = useSelector(customerSelector);
    const isDisable = isCustomerLogin ? true : permissions?.application?.edit;

    const [fieldError, setFieldError] = useState([
      {
        yearsAt: fieldRequiredMessage.yearsAt,
        monthAt: fieldRequiredMessage.monthsAt,
      },
    ]);

    const [timeAtAddress, setTimeAtAddress] = useState();

    const handleAddAddress = (newAddressValue) => {
      if (newAddressValue) {
        dispatch(addAddress({
          isCustomerLogin,
          data: {
            customerId: customer._id,
            address: newAddressValue, applicationId,
            isManualAddress: false,
            isEmployerAddress: false
          }
        }));

        setFieldError({
          yearsAt: fieldRequiredMessage.yearsAt,
          monthAt: fieldRequiredMessage.monthsAt,
        });
      }
    };

    const handleDeleteAddress = (newAddressValue) => {
      if (newAddressValue) {
        dispatch(
          deleteAddress({ isCustomerLogin, data: { customerId: customer._id, addressId: newAddressValue?._id, applicationId, isEmployerAddress: false } }),
        );
      }
    };

    useEffect(() => {
      const listOfAddress = customer?.addresses.map((_, i) => {
        if (i >= 1) {
          const result = {};

          // if (_?.monthsAt === 0) {
          //   result.monthAt = fieldRequiredMessage.monthAtInValid;
          // } else if (!_?.monthsAt) {
          if (!_?.monthsAt && _?.monthsAt !== 0) {
            result.monthAt = fieldRequiredMessage.monthsAt;
          } else {
            result.monthAt = "";
          }

          result.yearsAt = _?.yearsAt || _?.yearsAt === 0 ? "" : fieldRequiredMessage.yearsAt;

          return result;
        }

        return {
          monthAt: _?.monthsAt || _?.monthsAt === 0 ? "" : fieldRequiredMessage.monthsAt,

          yearsAt: _?.yearsAt || _?.yearsAt === 0 ? "" : fieldRequiredMessage.yearsAt,
        };
      });
      setFieldError(listOfAddress);
    }, [customer]);

    const handleUpdateAddress = (newAddressValue) => {
      if (newAddressValue) {
        const newErrors = [...fieldError];
        // if (newAddressValue.index >= 1 && newAddressValue?.monthsAt === 0) {
        //   newErrors[newAddressValue?.index].monthAt = fieldRequiredMessage.monthAtInValid;
        //   setFieldError(newErrors);
        //   return;
        // } else {
        dispatch(updateAddress({ isCustomerLogin, data: { ...newAddressValue, applicationId, isEmployerAddress: false } }));
        if (newAddressValue?.yearsAt || newAddressValue?.yearsAt === 0) {
          newErrors[newAddressValue?.index].yearsAt = "";
          setFieldError(newErrors);
          setFieldErrors({
            ...fieldErrors,
            yearsAt: "",
          });

          return;
        } else if (newAddressValue?.monthsAt || newAddressValue?.monthsAt === 0) {
          newErrors[newAddressValue?.index].monthAt = "";
          setFieldError(newErrors);
          setFieldErrors({
            ...fieldErrors,
            monthsAt: "",
          });

          return;
        }
        // }
      }
    };

    const timeAtAddressesLessThanSixMonths = () => {
      const timeAtAddressesRaw = customer?.addresses?.reduce(
        (acc, item) => {
          acc[0] = item.yearsAt * 1 + acc[0];
          acc[1] = item.monthsAt * 1 + acc[1];

          return acc;
        },
        [0, 0],
      );
      setTimeAtAddress(timeAtAddressesRaw[0] + timeAtAddressesRaw[1] / 12 < 5);
    };

    useEffect(() => {
      timeAtAddressesLessThanSixMonths();
    }, [customer]);

    const findEmail = customer?.emails?.find((email) => email.status === "current");

    const findMobileNumber = customer?.phoneNumbers?.find((number) => number.status === "current");

    const handle = {
      addManualAddress: (customerId) => {
        dispatch(saveValue({ isManualAddress: { show: true, customerId } }));
      }
    }

    const flipAddressBtnText = isManualAddress?.show && isManualAddress?.customerId === customer?._id
      ? 'Add Google Address'
      : 'Add Address Manually'

    return (
      <React.Fragment>
        <Grid
          container
          item
          xs={12}
          style={{
            margin: "0 0 30px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            paddingBottom: "20px",
          }}
        >
          <Grid item md={12} sm={12} style={{ margin: "0 0 10px" }}>
            <Typography id="input-slider" fontWeight={600}>
              Contact details
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={6}>
              <Email
                customerId={customer?._id}
                singleCustomerId={customerId}
                fieldErrors={fieldErrors}
                setFieldErrors={setFieldErrors}
                email={customer ? findEmail || customer?.emails?.slice(-1)[0] : ""}
                applicationId={applicationId}
                disabled={!isDisable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneNumber
                singleCustomerId={customerId}
                guarantorFieldErrors={fieldErrors}
                setGuarantorFieldErrors={setFieldErrors}
                customerId={customer?._id}
                phoneNumber={customer ? findMobileNumber || customer?.phoneNumbers?.slice(-1)[0] : ""}
                applicationId={applicationId}
                disabled={!isDisable}
              />
            </Grid>
          </Grid>

          {customer?.addresses.map((address, i) => (
            <Grid
              container
              item
              spacing={1}
              sm={12}
              md={12}
              key={address._id}
              alignItems="start"
              style={{ marginBottom: 5 }}
            >
              <Grid item sm={8} md={8}>
                <Typography variant="caption">{i < 1 ? "Current address" : "Previous address"}</Typography>
                <GoogleAddressSearch
                  customerId={customer?._id}
                  singleCustomerId={customerId}
                  guarantorFieldErrors={fieldErrors}
                  setGuarantorFieldErrors={setFieldErrors}
                  handleCreateAddress={handleAddAddress}
                  handleUpdateAddress={handleUpdateAddress}
                  handleRemoveAddress={handleDeleteAddress}
                  address={address}
                  index={i}
                  addressFieldName={`fullAddress_customer_${customer?._id}_${i}`}
                  disabled={!isDisable}
                />
              </Grid>
              <Grid item md={2} style={{ marginTop: "23px" }}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="filled"
                  error={fieldErrors?.yearsAt || fieldError[i]?.yearsAt}
                >
                  <InputLabel id="years-at-label">Years at</InputLabel>
                  <Select
                    labelId="years-at-label"
                    id="demo-simple-select"
                    value={address?.yearsAt}
                    label="Years at"
                    disabled={!isDisable}
                    error={fieldError[i]?.yearsAt || fieldErrors?.yearsAt}
                    onChange={(event) => {
                      handleUpdateAddress({
                        _id: address._id,
                        customerId: customer._id,
                        yearsAt: event.target.value,
                        address: address,
                        index: i,
                      });
                    }}
                  >
                    {[...Array(31).keys()].map((i) => (
                      <MenuItem value={i}>{i}</MenuItem>
                    ))}
                  </Select>
                  {(!isDisable ? false : fieldError[i]?.yearsAt || fieldErrors?.yearsAt) && (
                    <FormHelperText className={classes.helperText}>
                      {fieldError[i]?.yearsAt || fieldErrors?.yearsAt}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={2} style={{ marginTop: "23px" }}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="filled"
                  error={fieldErrors?.monthsAt || fieldError[i]?.monthAt}
                >
                  <InputLabel id="months-at-label">Months at</InputLabel>
                  <Select
                    labelId="months-at-label"
                    id="demo-simple-select"
                    value={address.monthsAt}
                    label="Months at"
                    error={fieldError[i]?.monthAt || fieldErrors?.monthsAt}
                    onChange={(event) => {
                      handleUpdateAddress({
                        _id: address._id,
                        customerId: customer._id,
                        applicationId: applicationId,
                        monthsAt: event.target.value,
                        address: address,
                        index: i,
                      });
                    }}
                  >
                    {[...Array(13).keys()].map((i) => (
                      <MenuItem value={i}>{i}</MenuItem>
                    ))}
                  </Select>
                  {(fieldError[i]?.monthAt || fieldErrors?.monthsAt) && (
                    <FormHelperText className={classes.helperText}>
                      {fieldError[i]?.monthAt || fieldErrors?.monthsAt}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          ))}

          {!isManualAddress?.show && isManualAddress?.customerId !== customer?._id &&
            (!customer?.addresses?.slice(-1)[0] || timeAtAddress) && (
              <Grid container item spacing={3} xs={12} mb={isManualAddress?.show ? 5 : 0} alignItems="end">
                <Grid item xs={12} sm={8}>
                  {timeAtAddress && (
                    <>
                      <Typography variant="caption">
                        {!customer?.addresses?.slice(-1)[0] ? "Add current address" : "Add previous address"}
                      </Typography>
                      <GoogleAddressSearch
                        customerId={customer?._id}
                        singleCustomerId={customerId}
                        guarantorFieldErrors={fieldErrors}
                        setGuarantorFieldErrors={setFieldErrors}
                        handleCreateAddress={handleAddAddress}
                        handleUpdateAddress={handleUpdateAddress}
                        handleRemoveAddress={handleDeleteAddress}
                        index={0}
                        address={""}
                        isEmptyAddress={true}
                        addressFieldName={`fullAddress_customer_${customer?._id}_${customer?.addresses.length}`}
                      // address={customer ? customer?.addresses?.slice(-1)[0] : ""}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            )}

          {isManualAddress?.show && isManualAddress?.customerId === customer?._id &&
            <Grid
              item
              sm={12}
              md={12}
            // mt={5}
            >
              <Typography variant="caption">
                {!customer?.addresses?.slice(-1)[0] ? "Add current address" : "Add previous address"}
              </Typography>
              <ManualAddress customer={customer} applicationId={applicationId} />
            </Grid>
          }

          {timeAtAddress &&
            <Grid
              item
              sm={4}
              md={4}
              xl={4}
              mt={2}
            >
              <Button
                variant="outlined"
                size="small"
                disabled={gettingAddressDetails} // only single manual address should be open at time
                // onClick={() => handle.addManualAddress(customer?._id)}
                onClick={() => {
                  if (isManualAddress?.show && isManualAddress?.customerId === customer?._id) {
                    dispatch(saveValue({ isManualAddress: { show: false, customerId: null } }));
                  } else {
                    handle.addManualAddress(customer?._id)
                  }
                }}
                style={{
                  height: '36px'
                }}
              >
                {/* {gettingAddressDetails ? 'Adding Address...' : 'Add Address Manually'} */}
                {gettingAddressDetails
                  ? 'Adding Address...'
                  : flipAddressBtnText
                }
              </Button>
            </Grid>
          }

          {/* <Grid item md={2} >
              <FormControl fullWidth size="small" variant="filled"
              // disabled={!customer?.addresses?.slice(-1)[0]}
              >
                <InputLabel id="years-at-label">Years at</InputLabel>
                <Select

                  labelId="years-at-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Years at"
                  onChange={event => {
                    handleUpdateAddress({ _id: customer?.addresses?.slice(-1)?.[0]?._id, yearsAt: event.target.value })
                  }}
                >
                  {[...Array(31).keys()].map(i => <MenuItem value={i}>{i}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl fullWidth size="small" variant="filled"
              // disabled={!customer?.addresses?.slice(-1)[0]}
              >
                <InputLabel id="months-at-label">Months at</InputLabel>
                <Select


                  labelId="months-at-label"
                  id="demo-simple-select"
                  // value={age}
                  label="Months at"
                  onChange={event => {
                    handleUpdateAddress({ _id: customer?.addresses?.slice(-1)?.[0]?._id, yearsAt: event.target.value })
                  }}
                >
                  {[...Array(13).keys()].map(i => <MenuItem value={i}>{i}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid> */}
        </Grid>
      </React.Fragment>
    );
  },
);

export default CustomerContactDetails;