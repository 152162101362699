import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Button, FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import _, { debounce } from "lodash";
import moment from "moment";
import clsx from "clsx";
import StatusCell from "./StatusCell";
import { getApplicationTab, STATUS_CODE } from "../../constants";
import { userSelector } from "../../store/slices/userSlice";
import { applicationsSelector, getAllApplications, saveValue } from "../../store/slices/applicationsSlice";
import DataGridTable from "../dataGridTable/DataGridTable";
import { getCloneApplication, userDetailsSelector } from "../../store/slices/applicationFormSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "61ch",
  },
}));

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

export default function ApplicationsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState("");
  const user = useSelector(userSelector);
  const {
    permissions: { application: applicationPermission },
  } = user;
  const { gettingClonedApplication } = useSelector(userDetailsSelector);
  const { applications, page, isLoading } = useSelector(applicationsSelector);
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [params, setParams] = useState({ offset: 1 });
  const [filterDate, setFilterDate] = useState({ startDate: null, endDate: null });

  const handle = {
    getAllApplicationsFn: ({ userId, cursor, search, startDate, endDate }) => {
      dispatch(
        getAllApplications({
          userId,
          cursor,
          search,
          requestedList: getApplicationTab.application,
          startDate,
          endDate,
        }),
      );
    },
    handleApplicationSearch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) &&
        handle.getAllApplicationsFn({ userId: user, cursor: params?.offset, search: searchTerm });
    },
    cloneApplication: (applicationId) => {
      dispatch(getCloneApplication(applicationId)).then(({ payload }) => {
        payload?.status_code === STATUS_CODE.success && navigate(`/application/${payload?.data?._id}`);
      });
    },
    onChangeDatePicker: (dates) => {
      const startDate = moment(dates?.[0]).format("YYYY-MM-DD");
      const endDate = moment(dates?.[1]).format("YYYY-MM-DD");

      setFilterDate({ startDate: dates?.[0], endDate: dates?.[1] });

      if ((dates[0] && dates[1]) || (!dates[0] && !dates[1])) {
        console.log({ startDate, endDate });
        handle.getAllApplicationsFn({
          userId: user,
          cursor: params?.offset,
          search: searchTerm,
          startDate: startDate === "Invalid date" ? undefined : startDate,
          endDate: endDate === "Invalid date" ? undefined : endDate,
        });
      }
    },
  };

  useEffect(() => {
    if (params?.offset) {
      const startDate = moment(filterDate?.startDate).format("YYYY-MM-DD");
      const endDate = moment(filterDate?.endDate).format("YYYY-MM-DD");
      handle.getAllApplicationsFn({
        userId: user,
        cursor: params?.offset,
        search: searchTerm,
        startDate: startDate === "Invalid date" ? undefined : startDate,
        endDate: endDate === "Invalid date" ? undefined : endDate,
      });
      dispatch(saveValue({ page: params?.offset }));
    }
  }, [params?.offset]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = useState(applications?.totalDocs || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) => applications?.totalDocs ?? prevRowCountState);
  }, [applications?.totalDocs]);

  // useEffect(() => {
  //   if (user) handle.getAllApplicationsFn(user, page || 1, searchTerm);
  // }, [user]);

  useEffect(() => {
    const transformApplication = (doc) => {
      const assetValueCheck = doc?.asset?.assetValue
        ? _.get(doc, "asset.assetValue").toLocaleString("en-US", dollarStringOptions)
        : "No Value";

      const date = new Date(_.get(doc, "createdAt"));
      const entityName = _.get(doc, "entities[0].entityName");
      const firstName = _.get(doc, "customers[0].firstName", "No");
      const lastName = _.get(doc, "customers[0].lastName", "customer");
      const assetType = _.get(doc, "asset.assetType", "Type not entered");
      const assetValue = assetValueCheck;
      const financeAmount =
        _.get(doc, "asset.assetValue", 0) -
        _.get(doc, "loanDetails.deposit", 0) -
        _.get(doc, "loanDetails.tradeIn", 0) +
        _.get(doc, "loanDetails.payout", 0);

      const capitalizedStatus = _.get(doc, "status", "").charAt(0).toUpperCase() + _.get(doc, "status", "").slice(1);
      const capitalizedApplicationType =
        _.get(doc, "applicationType", "").charAt(0).toUpperCase() + _.get(doc, "applicationType", "").slice(1);

      return _.pickBy(
        {
          humanId: _.get(doc, "humanId"),
          customer: entityName ? entityName : `${firstName} ${lastName}`,
          applicationType: capitalizedApplicationType,
          assetType,
          assetValue,
          loanAmount: _.get(doc, "loanDetails.loanAmount"),
          financeAmount: financeAmount ? financeAmount.toLocaleString("en-US", dollarStringOptions) : "Not entered",
          term: _.get(doc, "loanDetails.term"),
          id: _.get(doc, "_id"),
          startDate: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
          broker: {
            firstName: _.get(doc, "user.firstName"),
            lastName: _.get(doc, "user.lastName"),
            org: _.get(doc, "user.organisation[0].name"),
          },
          status: capitalizedStatus,
        },
        _.identity,
      );
    };

    const transformedRows = _.map(_.get(applications, "docs"), transformApplication);
    setRows(transformedRows);
    // time: moment(date).format("h:mm a"),
  }, [applications]);

  const debounced = useCallback(debounce(handle.handleApplicationSearch, 500), []);

  const handleCellEditCommit = useCallback(
    ({ id, field, value }) => {
      const updatedRows = rows.map((row) => (row.id === id ? { ...row, [field]: value } : row));
      setRows(updatedRows);
    },
    [rows],
  );

  const handleCellClick = React.useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (!event.currentTarget.contains(event.target)) {
      return;
    }
  }, []);

  const updateApplicationStatus = ({ _id, status }) => {
    const updatedRows = rows.map((row) => (row.id === _id ? { ...row, status: status } : row));
    setRows(updatedRows);
  };

  const masterViewColumns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "humanId",
      headerName: "ID",
      type: "string",
      width: 90,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Date",
      type: "string",
      width: 80,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>{params.value.date}</Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.time}</Typography>
        </div>
      ),
    },
    // {
    //   field: "time",
    //   headerName: "Time",
    //   type: "string",
    //   width: 80,
    //   editable: false,
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   type: "string",
    //   width: 100,
    //   editable: false,
    // },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      editable: applicationPermission?.status_change ? true : false,
      renderEditCell: (params) => <StatusCell params={params} updateApplicationStatus={updateApplicationStatus} />,
    },
    {
      field: "applicationType",
      headerName: "Application Type",
      type: "string",
      width: 110,
      editable: false,
    },
    {
      field: "broker",
      headerName: "Broker",
      type: "string",
      width: 120,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>
            {params.value.firstName} {params.value.lastName}
          </Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.org}</Typography>
        </div>
      ),
    },
    {
      field: "assetType",
      headerName: "Asset type",
      type: "string",
      width: 200,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "assetValue",
      headerName: "Asset value",
      type: "string",
      width: 110,
      editable: false,
    },
    {
      field: "customer",
      headerName: "Customer",
      width: 220,
      editable: false,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px", textWrap: "initial" }}>{params.value}</Typography>
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 220,
      editable: false,
      disableClickEventBubbling: gettingClonedApplication,
      renderCell: (params) => (
        <Button
          color="primary"
          variant="outlined"
          size="small"
          disabled={params?.id && gettingClonedApplication}
          onClick={(e) => {
            e.stopPropagation();
            handle.cloneApplication(params?.id);
          }}
        >
          Clone
        </Button>
      ),
    },
    // {
    //   field: "entityName",
    //   headerName: "Entity",
    //   width: 250,
    //   // editable: true,
    // },
    // {
    //   field: "firstName",
    //   headerName: "First name",
    //   width: 150,
    //   // editable: true,
    // },
    // {
    //   field: "lastName",
    //   headerName: "Last name",
    //   width: 150,
    //   // editable: true,
    // },

    // {
    //   field: "term",
    //   headerName: "Term",
    //   type: "string",
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: "serviceFees",
    //   headerName: "3rd Party fees",
    //   type: "string",
    //   width: 110,
    //   editable: true,
    // },
  ];

  return (
    <Grid container item direction="column" spacing={0} style={{ padding: "20px" }}>
      <Grid
        item
        style={{
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          alignItems: "center",
        }}
      >
        <Stack justifyContent={"space-between"} flexDirection={"row"} pb={"10px"}>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Typography
              variant="h1"
              style={{
                fontSize: "24px",
                fontWeight: 800,
                letterSpacing: "-0.5px",
                marginRight:"20px"
              }}
            >
              Applications
            </Typography>

            <FormControl className={clsx(classes.margin, classes.textField)}>
              <Input
                className="input-border-bottom"
                id="application-search"
                autoComplete="search" // false
                variant="filled"
                placeholder="Search…"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  debounced(e.target.value, 1000);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearchTerm("");
                          searchTerm !== "" && debounced("", 1000);
                        }}
                        color="secondary"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Stack>

          <Stack>
            <DatePicker
              selected={filterDate?.startDate}
              onChange={handle.onChangeDatePicker}
              startDate={filterDate?.startDate}
              endDate={filterDate?.endDate}
              selectsRange
              isClearable
              name="Select Date"
              monthsShown={2}
              customInput={<TextField label="Select Date" />}
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid item>
        <DataGridTable
          onCellClick={(params, event) => {
            // event.defaultMuiPrevented = true;
            if (params.field !== "status") return navigate(`/application/${params.id}`);
            handleCellClick(params, event);
          }}
          onRowClick={() => {}}
          data={rows || []}
          // columns={user.userType === "master" ? masterViewColumns : referralViewColumns}
          columns={masterViewColumns}
          rowsPerPageOptions={[10]}
          pageSize={10}
          rowCount={rowCountState}
          params={params}
          setParams={setParams}
          page={page}
          isLoading={isLoading}
          onCellEditStop={handleCellEditCommit}
        />
      </Grid>
    </Grid>
  );
}
