import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { getBusinessByABN, getBusinessByName } from "../../services/abr";
import { isDigitsOnly } from "../../utils/isDigitsOnly";
import { saveValue, userSelector } from "../../store/slices/userSlice";
import readableABNString from "../../utils/readableABNString";
const { useState, useEffect } = require("react");
const { Autocomplete, TextField, CircularProgress } = require("@mui/material");

function OrganisationSearch({ organisationDetails, setOrganisationDetails }) {

    const dispatch = useDispatch();
    const { gettingOrganisationDetails } = useSelector(userSelector);

    const [businessSearchValue, setBusinessSearchValue] = useState("");
    const [businessSearchInputText, setBusinessSearchInputText] = useState("");

    const [loadingBusinessSearch, setLoadingBusinessSearch] = useState("");
    const [organisationOptions, setOrganisationOptions] = useState([]);

    const debounced = debounce(async (inputValue) => {
        if (inputValue) {
            setLoadingBusinessSearch(true);
            if (isDigitsOnly.test(inputValue)) {
                const businessByABN = await getBusinessByABN(inputValue);
                setLoadingBusinessSearch(false);
                if (businessByABN.Abn.length > 1) {
                    const obj = {
                        "Abn": businessByABN.Abn,
                        "AbnStatus": businessByABN.AbnStatus === "Active" ? "0000000001" : "0000000002",
                        "IsCurrent": businessByABN.AbnStatus === "Active" ? true : false,
                        "Name": businessByABN.EntityName,
                        "Postcode": businessByABN.AddressPostcode,
                        "State": businessByABN.AddressState
                    }
                    return setOrganisationOptions([obj]);
                }
            }

            const businesses = await getBusinessByName(inputValue);
            if (businesses?.Names?.length > 0) {
                setLoadingBusinessSearch(false);
                return setOrganisationOptions(
                    businesses.Names.map((i) => 
                        // return { label: `${i.Name} ${i.Abn}`, Abn: i.Abn, value: i };
                         i
                    )
                );
            }

            setOrganisationOptions([
                {
                    Name: "No results found",
                },
            ]);
        }
    }, 500);

    const handleOrganisationDetails = async (details) => {
        dispatch(saveValue({ gettingOrganisationDetails: true }));
        const abrData = await getBusinessByABN(details.Abn);

        setOrganisationDetails(abrData);
        dispatch(saveValue({ gettingOrganisationDetails: false }));
    };


    useEffect(() => {
        debounced(businessSearchInputText);

        return () => debounced.cancel();
    }, [businessSearchValue, businessSearchInputText]);

    useEffect(() => {
        if (!gettingOrganisationDetails) {
            setBusinessSearchInputText("");
            setBusinessSearchValue("");
        }
    }, [gettingOrganisationDetails]);


    return (
        <>
            <Autocomplete
                fullWidth
                autoComplete
                autoSelect={true}
                name="Business Search"
                // {...props}
                // value={organisationDetails && organisationDetails.EntityName ? organisationDetails.EntityName : ""}
                noOptionsText="Type to start search"
                loadingText="Searching..."
                defaultValue=""
                clearOnBlur={true}
                blurOnSelect={true}
                onChange={(event, newValue, reason) => {
                    if (reason === "select-option") {
                        if (newValue) {
                            setBusinessSearchValue(
                                newValue?.Name || newValue?.organisationName
                            );

                            return;
                        }

                        setBusinessSearchValue("");
                        setBusinessSearchInputText("");
                    }

                    if (reason === "blur") {
                        setBusinessSearchInputText("");
                        handleOrganisationDetails(newValue);
                    }

                    if (reason === "clear") {
                        setBusinessSearchInputText("");
                        setOrganisationDetails(null);
                    }
                }}
                filterOptions={(x) => x}
                onInputChange={(event, newInputValue) => {
                    setBusinessSearchInputText(newInputValue);
                    if (newInputValue === "") setOrganisationOptions([]);
                }}
                getOptionLabel={(option) => option.Name
                        ? `${option.Name} ${readableABNString(option.Abn)}`
                        : ""}
                // inputValue={businessSearchInputText}
                options={organisationOptions}
                loading={loadingBusinessSearch}
                renderInput={(params) => (
                    <TextField
                        variant="filled"
                        {...params}
                        // label="Asynchronous"
                        label="Search with business name or ABN"
                        // value={organisationDetails && organisationDetails.EntityName ? organisationDetails.EntityName : ""}
                        placeholder="Search with ABN or business name"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loadingBusinessSearch ? <CircularProgress /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />

        </>
    )
}

export default OrganisationSearch;