import React, { useState, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Typography,
  Box,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import RequestQuoteIcon from "@mui/icons-material/RequestQuoteTwoTone";
import * as Yup from "yup";
import moment from "moment";

// import checkAlphabetRestrict from "src/utils/checkAlphabetRestrict";
import { debounce } from "lodash";
import { APPLICATION_TYPES, LOAN_PURPOSE_TYPES } from "../../constants";
import regex from "../../utils/regex";
import { NumericFormatCustom, currencyMaskedValue } from "../../utils/currencyMaskFormat";
import {
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails,
  assetSelector,
  saveAsset,
  updateAsset,
  userDetailsSelector,
} from "../../store/slices/applicationFormSlice";
import { isValidInput } from "../Utils/helperFunction";
import { userPermissionsSelector } from "../../store/slices/userSlice";
// import { isValidInput } from "../Utils/helperFunction";

const dollarStringOptions = {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
};

const LoanDetails = React.memo(({ applicationType, isManualQuoteOpen }) => {
  const dispatch = useDispatch();
  const permissions = useSelector(userPermissionsSelector);
  const isDisable = permissions?.application?.edit;

  const { openManualQuote } = useSelector(userDetailsSelector);
  const loanDetails = useSelector(loanDetailsSelector);
  const asset = useSelector(assetSelector);
  const {
    _id,
    balloon = 0,
    balloonAmount = 0,
    tradeIn = null,
    payout = null,
    deposit = null,
    loanAmount = null,
    purpose,
    term,
    brokerage,
    brokerageAmount,
    originationFee,
    rateAdjustment = 0,
  } = loanDetails;

  const { ageOfAsset = "", assetValue } = asset; // purpose ageOfAssetAtEnd

  const [fieldErrors, setFieldErrors] = useState({
    deposit: "",
    loanAmount: "",
    tradeIn: "",
    payout: "",
    term: "",
    assetValue: "",
    purpose: "",
    originationFee: "",
    brokerage: "",
    brokerageAmount: "",
  });

  const validationSchema = Yup.object({
    deposit: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    loanAmount: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    tradeIn: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    payout: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    assetValue: Yup.string()
      .optional()
      .matches(regex.allowOnlyNumber, "Only numbers and and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    originationFee: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    purpose: Yup.string().optional(),
    // brokerage: Yup.string()
    //   .optional()
    //   .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
    //   .matches(regex.allowNumberAndDecimal, {
    //     message: "Number must be to up 7 digit and up to 2 decimal places",
    //   })
    //   .max(10, "Maximum of 7 digits"),
    term: Yup.string()
      .optional()
      .matches(regex.termsRegex, {
        message: "Terms must be up to 84 months",
      })
      .max(2, "Maximum of 2 digits"),
    brokerage: Yup.string()
      .optional()
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    brokerageAmount: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
    balloon: Yup.string()
      .optional()
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    balloonAmount: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 2 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
  });

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    }, 300),
    []
  );

  // const validateField = async (fieldName, value) => {
  //   try {
  //     await validationSchema.validateAt(fieldName, { [fieldName]: value });
  //     setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
  //   } catch (error) {
  //     setFieldErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [fieldName]: error.message,
  //     }));
  //   }
  // };

  const [allValues, setAllValue] = useState({
    deposit: deposit || "",
    loanAmount: loanAmount || "",
    balloon: balloon || 0,
    balloonAmount: balloonAmount || 0,
    term: term,
    tradeIn: tradeIn || "",
    payout: payout || "",
    purpose: purpose,
    assetValue: assetValue || "",
    brokerage: brokerage || 0,
    brokerageAmount: brokerageAmount || 0,
    originationFee: originationFee || "",
    rateAdjustment: rateAdjustment || 0,
  });

  // const [mode, setMode] = useState("percentage");
  const [mode, setMode] = useState({ balloon: "percentage", brokerage: "percentage" });
  // const isPercentageCheck = mode === "percentage";
  const isBallonPerCheck = mode.balloon === "percentage";
  const isBrokeragePerCheck = mode.brokerage === "percentage";
  const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;

  const handleModeChange = (key, newMode) => {
    if (newMode !== null) {
      if (key === "brokerage") {
        if (mode.brokerage !== "percentage") {
          debouncedValidation("brokerage", allValues.brokerage);
        } else {
          debouncedValidation("brokerageAmount", allValues.brokerageAmount);
        }
      } else if (key === "balloon") {
        if (mode.balloon !== "percentage") {
          debouncedValidation("balloon", allValues.balloon);
        } else {
          debouncedValidation("balloonAmount", allValues.balloonAmount);
        }
      }
      // setMode(newMode);
      setMode({
        ...mode,
        [key]: newMode
      });
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      // if (e === "") return setAllValue({ ...allValues, [name]: e });
      if (e === "") {
        setAllValue({ ...allValues, [name]: e });

        // Condition added due to set error for terms
        if (name !== "term") {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
          return;
        }
      }

      if (
        name === "deposit" ||
        name === "tradeIn" ||
        name === "payout" ||
        name === "assetValue" ||
        name === "originationFee"
      ) {
        isValid = isValidInput(e);
      }
      if (!isValid) {
        // await validateField(name, e);
        debouncedValidation(name, e);
        return;
      }

      if (name === "brokerage") {
        if (mode.brokerage === "percentage") {
          if (e < 0) {
            debouncedValidation(name, e);
            return;
          }
          setAllValue({ ...allValues, [name]: e, brokerageAmount: handle.calcBrokerageAmount(e) });
          debouncedValidation(name, e);
          return
        } else {
          setAllValue({ ...allValues, [name]: handle.calcBrokeragePercentage(e), brokerageAmount: Math.abs(e) });
          // debouncedValidation(name, e);
        }
      } else if (name === "balloon") {
        if (mode.balloon === "percentage") {
          if (e < 0) {
            debouncedValidation(name, e);
            return;
          }
          setAllValue({ ...allValues, [name]: +e, balloonAmount: handle.calcBalloonAmount(e) });
          debouncedValidation(name, e);
          return
        } else {
          setAllValue({ ...allValues, [name]: handle.calcBalloonPercentage(e), balloonAmount: Math.abs(e) });
          // debouncedValidation(name, e);
        }
      } else {
        if (name === "term") {
          setAllValue({ ...allValues, [name]: +e });
        } else {
          setAllValue({ ...allValues, [name]: e });
        }
        debouncedValidation(name, e);
      }
    },
    calcBrokerageAmount: (brokerage) => {
      const brokerageAmount = netAssetValue * (brokerage / 100);
      return brokerageAmount;
    },

    calcBrokeragePercentage: (brokerageAmount) => {
      // const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
      const brokeragePercentage = (brokerageAmount / netAssetValue) * 100;
      return brokeragePercentage && !isNaN(brokeragePercentage) && isFinite(brokeragePercentage)
        ? brokeragePercentage
        : 0;
    },
    calcBalloonAmount: (balloon) => {
      const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
      const balloonAmount = netAssetValue * balloon / 100
      return balloonAmount;
    },
    calcBalloonPercentage: (balloonAmount) => {
      const balloonPercentage = balloonAmount / netAssetValue * 100;
      return balloonPercentage && !isNaN(balloonPercentage) && isFinite(balloonPercentage)
        ? balloonPercentage
        : 0;
    },
    loanDetailsFn: (value) => {
      const keys = Object.keys(value);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (loanDetails[key] !== value[key]) {
          dispatch(saveLoanDetails(value));
          dispatch(updateLoanDetails({ _id, ...value }));
        }
      }
    },
    termValueFn: () => {
      if (allValues?.term === loanDetails.term) {
        return;
      }

      if (+allValues?.term > 0 && +allValues?.term <= 84) {
        const termInYears = allValues?.term / 12;
        const ageOfAssetMoment = moment(ageOfAsset, "YYYY");
        const endOfTermYear = moment().add(termInYears, "year");
        const ageOfAssetAtEnd = endOfTermYear.diff(ageOfAssetMoment, "years");
        handle.apiCallFn(ageOfAssetAtEnd);
      }
    },
    apiCallFn: (ageOfAssetAtEnd) => {
      Promise.all([
        dispatch(saveLoanDetails({ term: allValues?.term })),
        dispatch(updateAsset({ _id: asset._id, ageOfAssetAtEnd })),
        dispatch(updateLoanDetails({ _id, term: allValues?.term })),
      ]);
    },
    blurFn: async (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "")
          handle.loanDetailsFn({
            [fieldName]: value || 0,
          });
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
    blurFnPersonalType: (fieldName, value) => {
      try {
        if (fieldErrors[fieldName] === "") dispatch(saveAsset({ [fieldName]: value }));
        if (_id)
          dispatch(
            updateAsset({
              ...asset,
              [fieldName]: value,
            }),
          );
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        setFieldErrors(newErrors);
      }
    },
  };

  return (
    <Grid
      container
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={12} sm={12} md={2}>
        <RequestQuoteIcon style={{ marginRight: "10px" }} />
        <Typography>Loan Details</Typography>
      </Grid>

      <Grid
        conatiner
        xs={12}
        sm={12}
        md={10}
        style={{
          padding: "0 0 0 10px",
        }}
      >
        {(applicationType === APPLICATION_TYPES.COMMERCIAL || applicationType === APPLICATION_TYPES.CONSUMER) && (
          <Grid container item style={{ margin: "0 0 10px" }}>
            <Grid container item spacing={1}>
              <Grid item sm={4} xs={12}>
                <Stack direction="row">
                  <TextField
                    fullWidth
                    id="deposit"
                    label="Deposit amount"
                    variant="filled"
                    type="text"
                    size="small"
                    name="deposit"
                    disabled={!isDisable}
                    value={allValues?.deposit}
                    error={fieldErrors?.deposit}
                    helperText={fieldErrors?.deposit}
                    onChange={(event) => handle.onChangeField(event?.target?.value, "deposit")}
                    onBlur={(e) => handle.blurFn("deposit", currencyMaskedValue(e.target.value))}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      style: {
                        borderTopRightRadius: 0,
                      },
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item md={4} sm={3}>
                <TextField
                  fullWidth
                  id="tradeIn"
                  variant="filled"
                  type="text"
                  name="tradeIn"
                  label="Trade in amount"
                  size="small"
                  disabled={!isDisable}
                  value={allValues?.tradeIn}
                  error={fieldErrors?.tradeIn}
                  helperText={fieldErrors?.tradeIn}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) => handle.onChangeField(event?.target?.value, "tradeIn")}
                  onBlur={(e) => handle.blurFn("tradeIn", currencyMaskedValue(e.target.value))}
                />
              </Grid>
              <Grid item md={4} sm={3}>
                <TextField
                  fullWidth
                  id="payout"
                  variant="filled"
                  type="text"
                  name="payout"
                  label="Payout amount"
                  size="small"
                  value={allValues?.payout}
                  error={fieldErrors?.payout}
                  helperText={fieldErrors?.payout}
                  disabled={!isDisable}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                  }}
                  onChange={(event) => handle.onChangeField(event?.target?.value, "payout")}
                  onBlur={(e) => handle.blurFn("payout", currencyMaskedValue(e.target.value))}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12} style={{ margin: "0 0 10px" }}>
          <Grid container item spacing={1}>
            {applicationType === APPLICATION_TYPES.PERSONAL && (
              <Fragment>
                <Grid item md={4} sm={4}>
                  <TextField
                    id="loanAmount"
                    fullWidth
                    variant="filled"
                    type="text"
                    name="loanAmount"
                    label="Loan amount"
                    size="small"
                    disabled={!isDisable}
                    value={allValues?.loanAmount}
                    error={fieldErrors?.loanAmount}
                    helperText={fieldErrors?.loanAmount}
                    onChange={(event) => handle.onChangeField(event?.target?.value, "loanAmount")}
                    onBlur={(e) => handle.blurFn("loanAmount", currencyMaskedValue(e.target.value))}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      style: {
                        borderTopRightRadius: 0,
                      },
                    }}
                  />
                </Grid>

                <Grid item md={4} sm={4}>
                  <TextField
                    fullWidth
                    variant="filled"
                    style={{ background: "#ffffff" }}
                    select
                    value={allValues?.purpose}
                    label="Loan purpose"
                    size="small"
                    name="purpose"
                    disabled={!isDisable}
                    onChange={(event) => handle.onChangeField(event?.target?.value, "purpose")}
                    onBlur={(e) => handle.blurFn("purpose", e.target.value)}
                  >
                    {LOAN_PURPOSE_TYPES.map((purpose, i) => (
                      <MenuItem value={purpose}>{purpose}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Fragment>
            )}

            {applicationType === APPLICATION_TYPES.CONSUMER && !openManualQuote && (
              <Fragment>
                <Grid item sm={4} xs={12}>
                  <Stack direction="column">
                    <Typography variant="caption">Rate adjustment {rateAdjustment}%</Typography>
                    <Box style={{ padding: "0 7px" }}>
                      <Slider
                        color="secondary"
                        size="large"
                        marks={[
                          { value: -2, label: "-2" },
                          { value: -1.5, label: "-1.5" },
                          { value: -1, label: "-1" },
                          { value: -0.5, label: "-0.5" },
                          { value: 0, label: "0" },
                          // { value: 0.5, label: "0.5" },
                          // { value: 1, label: "1" },
                          // { value: 1.5, label: "1.5" },
                          // { value: 2, label: "2" },
                        ]}
                        step={0.5}
                        min={-2}
                        max={0}
                        value={allValues?.rateAdjustment}
                        name="rateAdjustment"
                        onChange={(event) => handle.onChangeField(event?.target?.value, "rateAdjustment")}
                        onChangeCommitted={() =>
                          handle.loanDetailsFn({
                            rateAdjustment: allValues?.rateAdjustment,
                          })
                        }
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </Stack>
                  {/* <TextField
                    id="originationFee"
                    fullWidth
                    variant="filled"
                    type="text"
                    name="originationFee"
                    label="Brokerage (%)"
                    size="small"
                    value={allValues?.brokerageAdjustment}
                    // error={allValues?.brokerageAdjustment}
                    // helperText={fieldErrors?.originationFee}
                    onChange={(event) =>
                      handle.onChangeField(event?.target?.value, "brokerageAdjustment")
                    }
                    onBlur={(e) =>
                      handle.loanDetailsFn({
                        brokerageAdjustment: allValues?.brokerageAdjustment,
                      })
                    }
                  /> */}
                </Grid>
              </Fragment>
            )}

            <Grid item sm={4} xs={12}>
              {/* <Stack direction="column">
                <Typography variant="caption">Repayment term (in months)</Typography>
                <Box style={{ padding: "0 7px" }}>
                  <Slider
                    color="secondary"
                    size="large"
                    marks={[
                      { value: 12, label: 12 },
                      { value: 24, label: 24 },
                      { value: 36, label: 36 },
                      { value: 48, label: 48 },
                      { value: 60, label: 60 },
                      { value: 72, label: 72 },
                      { value: 84, label: 84 },
                    ]}
                    step={12}
                    min={12}
                    max={84}
                    value={parseInt(allValues?.termValue)}
                    name="termValue"
                    onChange={(event) => handle.onChangeField(event?.target?.value, "termValue")}
                    onChangeCommitted={() => handle.termValueFn()}
                    defaultValue={60}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  />
                </Box>
              </Stack> */}
              {/* <Box display="flex" alignItems="end"> */}
              <Stack direction="column">
                <Typography variant="caption" sx={{ m: 0, p: 0 }}>Repayment term (in months)</Typography>
                <TextField
                  fullWidth
                  id="term"
                  // label="Repayment term (in months)"
                  label="Repayment term"
                  variant="filled"
                  type="text"
                  size="small"
                  name="term"
                  disabled={!isDisable}
                  value={allValues?.term}
                  error={fieldErrors?.term}
                  helperText={fieldErrors?.term}
                  onChange={(event) => handle.onChangeField(event?.target?.value, "term")}
                  // onBlur={(e) => handle.blurFn("term", e.target.value)}
                  onBlur={(e) => handle.termValueFn()}
                // sx={{ mt: '20px' }}
                // style={{ display: 'flex', alignItems: 'end', background: "#ffffff" }}
                />
              </Stack>
              {/* </Box> */}
            </Grid>

            {(applicationType === APPLICATION_TYPES.COMMERCIAL || applicationType === APPLICATION_TYPES.CONSUMER) && (
              // <Fragment>
              //   <Grid item sm={4} xs={12}>
              //     <Stack direction="column">
              //       <Typography variant="caption">Balloon</Typography>
              //       <Box style={{ padding: "0 7px" }}>
              //         <Slider
              //           color="secondary"
              //           size="large"
              //           marks={[
              //             { value: 0, label: 0 },
              //             { value: 10, label: 10 },
              //             { value: 20, label: 20 },
              //             { value: 30, label: 30 },
              //             { value: 40, label: 40 },
              //           ]}
              //           step={5}
              //           min={0}
              //           max={40}
              //           value={allValues?.balloonValue}
              //           name="balloonValue"
              //           onChange={(event) => handle.onChangeField(event?.target?.value, "balloonValue")}
              //           onChangeCommitted={() =>
              //             handle.loanDetailsFn({
              //               balloon: allValues?.balloonValue,
              //             })
              //           }
              //           aria-label="Default"
              //           valueLabelDisplay="auto"
              //         />
              //       </Box>
              //     </Stack>
              //   </Grid>
              // </Fragment>

              <Fragment>
                <Grid item sm={4} xs={12}>
                  <Stack direction="column">
                    <Typography variant="caption">
                      Balloon {!isNaN(allValues?.balloon) ? Math.round(allValues?.balloon * 100) / 100 : 0}% |{" "}
                      {(allValues?.balloonAmount ? allValues?.balloonAmount : 0).toLocaleString(
                        "en-US",
                        dollarStringOptions,
                      )}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <TextField
                        label="Balloon"
                        name="balloon"
                        value={isBallonPerCheck ? allValues?.balloon : allValues?.balloonAmount}
                        error={isBallonPerCheck ? fieldErrors?.balloon : fieldErrors?.balloonAmount}
                        helperText={isBallonPerCheck ? fieldErrors?.balloon : fieldErrors?.balloonAmount}
                        onChange={(event) => {
                          const value = event.target.value;
                          // Handle clearing the value properly
                          handle.onChangeField(value || 0, "balloon");
                        }}
                        onBlur={() => {
                          handle.loanDetailsFn({
                            balloon: +allValues?.balloon || 0,
                            balloonAmount: +allValues?.balloonAmount || 0,
                          });
                        }}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                          inputProps: {
                            prefix: "",
                            isAllowedFlag: true,
                            maxDigits: 7,
                            maxPlaces: 2,
                          },
                          startAdornment: allValues?.balloonAmount > 0 && mode.balloon !== "percentage" && (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <ToggleButtonGroup
                                value={mode.balloon}
                                exclusive
                                color="primary"
                                onChange={(event, value) => handleModeChange("balloon", value)}
                                sx={{
                                  mr: -1,
                                  height: "36px",
                                }}
                              >
                                <ToggleButton value="percentage">%</ToggleButton>
                                <ToggleButton value="dollar">$</ToggleButton>
                              </ToggleButtonGroup>
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        size="small"
                        variant="filled"
                        fullWidth
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Fragment>
            )}

            {/* </Grid>
        </Grid>
        <Grid container item xs={12} style={{ margin: "0 0 10px" }}>
          <Grid container item spacing={2} alignItems="center"> */}

            {applicationType === APPLICATION_TYPES.COMMERCIAL && !openManualQuote && (
              <Fragment>
                <Grid item sm={4} xs={12}>
                  <Stack direction="column">
                    <Typography variant="caption">
                      Brokerage {!isNaN(allValues?.brokerage) ? Math.round(allValues?.brokerage * 100) / 100 : 0}% |{" "}
                      {(allValues?.brokerageAmount ? allValues?.brokerageAmount : 0).toLocaleString(
                        "en-US",
                        dollarStringOptions,
                      )}
                    </Typography>
                    {/* Original brokerage slider input */}
                    {/* <Box style={{ padding: "0 7px" }}>
                      <Slider
                        color="secondary"
                        size="large"
                        marks={[
                          { value: 0, label: 0 },
                          { value: 2, label: "2" },
                          { value: 4, label: "4" },
                          { value: 6, label: "6" },
                          { value: 8, label: "8" },
                          { value: 10, label: "10" },
                          { value: 12, label: "12" },
                        ]}
                        step={1}
                        min={0}
                        max={12}
                        disabled={!isDisable}
                        value={allValues?.brokerage}
                        name="brokerage"
                        onChange={(event) => handle.onChangeField(event?.target?.value, "brokerage")}
                        onChangeCommitted={() =>
                          handle.loanDetailsFn({
                            brokerage: allValues?.brokerage,
                            brokerageAmount: handle.calcBrokerageAmount(allValues?.brokerage),
                          })
                        }
                        aria-label="Default"
                        valueLabelDisplay="auto"
                      />
                    </Box> */}
                    <Box display="flex" alignItems="center">
                      <TextField
                        label="Brokerage"
                        name="brokerage"
                        value={isBrokeragePerCheck ? allValues?.brokerage : allValues?.brokerageAmount}
                        error={isBrokeragePerCheck ? fieldErrors?.brokerage : fieldErrors?.brokerageAmount}
                        helperText={isBrokeragePerCheck ? fieldErrors?.brokerage : fieldErrors?.brokerageAmount}
                        onChange={(event) => {
                          const value = event.target.value;
                          // Handle clearing the value properly
                          handle.onChangeField(value || 0, "brokerage");
                        }}
                        onBlur={() => {
                          handle.loanDetailsFn({
                            brokerage: allValues?.brokerage || 0,
                            brokerageAmount: allValues?.brokerageAmount || 0,
                          });
                        }}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                          inputProps: {
                            prefix: "",
                            isAllowedFlag: true,
                            maxDigits: 7,
                            maxPlaces: 2,
                          },
                          startAdornment: allValues?.brokerageAmount > 0 && mode.brokerage !== "percentage" && (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <ToggleButtonGroup
                                value={mode.brokerage}
                                exclusive
                                color="primary"
                                // onChange={handleModeChange}
                                onChange={(event, value) => handleModeChange("brokerage", value)}
                                sx={{
                                  mr: -1,
                                  height: "36px",
                                }}
                              >
                                <ToggleButton value="percentage">%</ToggleButton>
                                <ToggleButton value="dollar">$</ToggleButton>
                              </ToggleButtonGroup>
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        size="small"
                        variant="filled"
                        fullWidth
                      />
                    </Box>
                  </Stack>
                  {/* <TextField
                    id="originationFee"
                    fullWidth
                    variant="filled"
                    type="text"
                    name="originationFee"
                    label="Brokerage (%)"
                    size="small"
                    value={allValues?.brokerageAdjustment}
                    // error={allValues?.brokerageAdjustment}
                    // helperText={fieldErrors?.originationFee}
                    onChange={(event) =>
                      handle.onChangeField(event?.target?.value, "brokerageAdjustment")
                    }
                    onBlur={(e) =>
                      handle.loanDetailsFn({
                        brokerageAdjustment: allValues?.brokerageAdjustment,
                      })
                    }
                  /> */}
                </Grid>
              </Fragment>
            )}
            {/* <Grid item sm={4} xs={12}>
              <TextField
                id="originationFee"
                fullWidth
                variant="filled"
                type="text"
                name="originationFee"
                label="Origination fee"
                size="small"
                value={allValues?.originationFee || "Lender max"}
                error={fieldErrors?.originationFee}
                helperText={fieldErrors?.originationFee}`
                onChange={(event) =>
                  handle.onChangeField(event?.target?.value, "originationFee")
                }
                onBlur={(e) =>
                  handle.blurFnPersonalType("originationFee", e.target.value)
                }
              />
            </Grid> */}
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={4}>
          <TextField
            fullWidth
            id="originationFee"
            label="Origination Fee"
            variant="filled"
            type="text"
            size="small"
            name="originationFee"
            value={allValues?.originationFee}
            error={fieldErrors?.originationFee}
            helperText={fieldErrors?.originationFee}
            onChange={(event) => handle.onChangeField(event?.target?.value, "originationFee")}
            onBlur={(e) => handle.blurFn("originationFee", currencyMaskedValue(e.target.value))}
            InputProps={{
              inputComponent: NumericFormatCustom,
              style: {
                borderTopRightRadius: 0,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});

export default LoanDetails;
