import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import MuiContainer from "@mui/material/Container";

import { styled } from "@mui/material";
// import MuiButton from "@mui/material/Button";
import { userSelector } from "../store/slices/userSlice";
import TopMenu from "../layouts/TopMenu";

import { userDetailsSelector } from "../store/slices/applicationFormSlice";

// const drawerWidth = 300;

const { innerWidth: width } = window;
// , innerHeight: height
const Container = styled(MuiContainer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `${(width - 1200) / 2 - 300}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: -30,
  }),
}));

// const Button = styled(MuiButton, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     // width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const pathRegex = new RegExp("dashboard/application/");

export default function Main(props) {
  // const dispatch = useDispatch();
  // const location = useLocation();
  // const [buttonText, setButtonText] = useState("Open");
  const { open } = useSelector(userDetailsSelector);

  const user = useSelector(userSelector);

  // const handleDrawer = () => {
  //   dispatch(saveValue({ open: !open }));
  // };

  // useEffect(() => {
  //   open ? setButtonText("Close") : setButtonText("Open");
  // });

  useEffect(() => {}, [user]);

  return (
    <>
      {/* {(user?.status === "pendingApproval") ? <div style={{
        postion: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: "#ffffff",
        zIndex: 10000000,
        height: "100vh",
        width: "100vw",
        padding: "50px"
      }}>
        <h3>Your account is prending approval.</h3>
        <p>You will be notified via email when your account is active and you can use the platform.</p>
      </div> : <> */}
      {/* <Button
          size="small"
          open={open}
          variant="contained"
          onClick={handleDrawer}
          color="secondary"
          style={{
            position: "fixed",
            left: "-1px",
            // top: "calc(50vh - 76px)",
            top: "115px",
            textOrientation: "upright",
            padding: "5px 16px 10px",
            minWidth: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            lineHeight: 0,
            // background: "#fff",
            display: location.pathname.match(pathRegex) ? "block" : "none",
            // marginLeft: "-1px"
            zIndex: 10000000
          }}
        >
          <span
            style={{
              textOrientation: "upright",
              writingMode: "vertical-rl",
              letterSpacing: "-2px",
            }}
          >
            {buttonText} Notes
          </span>
        </Button> */}
      <TopMenu open={open} />
      {/* <Box sx={{ display: "flex", margin: "auto" }}> */}
      {/* <Notes open={open} /> */}

      <Container
        open={open}
        maxWidth="xl"
        style={{
          borderRadius: "4px",
          margin: "84px auto 0",
          // background: "#ffffff",
          padding: "0 20px",
        }}
      >
        {/* <Grid container style={{ height: "100%" }}> */}
        {/* <Grid item xs={12}> */}
        {/* <Application {...props} /> */}

        <Outlet />
        {/* </Grid> */}
        {/* </Grid> */}
      </Container>
      {/* </Box> */}
      {/* </> */}
      {/* } */}
    </>
  );
}
