import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, TextField, Stack, FormControlLabel, Checkbox } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import regex from "../../utils/regex";
import { registerUser, saveValue, userSelector, validateEmail, validatePhone } from "../../store/slices/userSlice";
import GoogleAddressSearch from "../Utils/GoogleAddressSearchRegister";
import OrganisationSearch from "../Utils/OrganisationSearch";

// function useQuery() {
//   const { search } = useLocation();

//   return useMemo(() => new URLSearchParams(search), [search]);
// }

export default function Application() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const query = useQuery();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  // const [lastNameError, setLastNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  // const [lastNameErrortext, setLastNameErrorText] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgNameError, setOrgNameError] = useState(false);
  const [orgNameErrorText, setOrgNameErrorText] = useState("");
  const [abn, setABN] = useState("");
  const [abnError, setABNError] = useState(false);
  const [abnErrorText, setABNErrorText] = useState("");
  const [acn, setACN] = useState("");
  const [acnError, setACNError] = useState(false);
  const [acnErrorText, setACNErrorText] = useState("");
  const [acl, setACL] = useState("");
  const [aclError, setACLError] = useState(false);
  const [aclErrorText, setACLErrorText] = useState("");
  const [address, setAddress] = useState("");
  // const [addressError, setAddressError] = useState(false);
  // const [addressErrorText, setAddressErrorText] = useState("");
  const [addressErrors, setAddressErrors] = useState({
    address: null,
  });

  const [organisationDetails, setOrganisationDetails] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  // const [hasOrganisation, setHasOrganisation] = useState(null);

  const { emailInUse, phoneInUse, isCreatingAccount, createAccountSuccess } = useSelector(userSelector);

  async function handleRegistration() {
    // dispatch(saveValue({ isCreatingAccount: true }));
    await dispatch(
      registerUser({
        firstName,
        lastName,
        phoneNumber,
        email,
        orgName,
        abn,
        acn,
        acl,
        address,
        // organisation: hasOrganisation,
      }),
    ).unwrap();
  }

  useEffect(() => {
    if (organisationDetails) {
      setOrgName(organisationDetails?.EntityName);
      setABN(organisationDetails?.Abn);
      setACN(organisationDetails?.Acn);
    } else {
      setOrgName("");
      setABN("");
      setACN("");
    }
  }, [organisationDetails]);

  function handleFullName(event) {
    setFullName(event.target.value);
    if (regex.name.test(event.target.value)) {
      setFirstNameError(false);
      return setFirstNameErrorText("");
    }
    setFirstNameError(true);
    setFirstNameErrorText("Name can contain only letters, dashes (-) or spaces.");
  }

  // function handleLastName(event) {
  //   setLastName(event.target.value);

  //   if (regex.name.test(event.target.value)) {
  //     setLastNameError(false);

  //     return setLastNameErrorText("");
  //   }

  //   setLastNameError(true);
  //   setLastNameErrorText("Name can contain only letters, dashes (-) or spaces.");
  // }

  function handlePhoneNumber(event) {
    setPhoneNumber(event.target.value.trim());
    if (/^0[45]\d{8}$/.test(event.target.value.toLowerCase())) {
      setPhoneNumberError(false);
      dispatch(saveValue({ phoneInUse: false }));
      return setPhoneNumberErrorText("");
    }
    setPhoneNumberError(true);
    setPhoneNumberErrorText("Not a valid Australia mobile phone number.");
  }

  function handleEmail(event) {
    setEmail(event.target.value.trim());

    if (regex.email.test(event.target.value.toLowerCase())) {
      setEmailError(false);
      dispatch(saveValue({ emailInUse: false }));

      return setEmailErrorText("");
    }

    setEmailError(true);
    setEmailErrorText("Not a valid email address.");
  }

  function handleOrgName(event) {
    setOrgName(event.target.value.trim());

    if (regex.orgNameRegex.test(event.target.value)) {
      setOrgNameError(false);

      return setOrgNameErrorText("");
    }

    setOrgNameError(true);
    setOrgNameErrorText("Not a valid Name.");
  }

  function handleABN(event) {
    setABN(event.target.value.trim());

    if (regex.ABN.test(event.target.value)) {
      setABNError(false);

      return setABNErrorText("");
    }

    setABNError(true);
    setABNErrorText("Not a valid ABN.");
  }

  function handleACN(event) {
    setACN(event.target.value.trim());

    if (regex.ACN.test(event.target.value)) {
      setACNError(false);

      return setACNErrorText("");
    }

    setACNError(true);
    setACNErrorText("Not a valid ACN.");
  }

  function handleACL(event) {
    setACL(event.target.value.trim());

    if (regex.ACR.test(event.target.value)) {
      setACLError(false);

      return setACLErrorText("");
    }

    setACLError(true);
    setACLErrorText("Not a valid ACR.");
  }

  // function handleAddress(event) {
  //   setAddress(event.target.value.trim());

  //   if (regex.addressRegex.test(event.target.value)) {
  //     setAddressError(false);

  //     // return setAddressErrorText("");
  //   }

  //   setAddressError(true);
  //   // setAddressErrorText("Not a valid Address.");
  // }

  async function handleCheckEmail() {
    if (email && email.length) {
      await dispatch(validateEmail(email)).unwrap();
    }
  }

  async function handleCheckPhoneNumber() {
    if (phoneNumber && phoneNumber.length) {
      await dispatch(validatePhone(phoneNumber)).unwrap();
    }
  }

  const handle = {
    blurFn: (fieldName) => {
      if (fieldName === "fullname") {
        if (fullName !== "") {
          const fullNameValue = fullName.split(" ");
          setFirstName(fullNameValue[0]);
          setLastName(fullNameValue[1]);
        } else {
          setFirstNameError(true);
          setFirstNameErrorText("Full name is required!");
        }
        return;
      } else if (fieldName === "phoneNumber") {
        if (phoneNumber !== "") {
          handleCheckPhoneNumber();
        } else {
          setPhoneNumberError(true);
          setPhoneNumberErrorText("Phone Number is required!");
        }
        return;
      } else if (fieldName === "email") {
        if (email !== "") {
          handleCheckEmail();
        } else {
          setEmailError(true);
          setEmailErrorText("Email is required!");
        }
      } else if (fieldName === "orgName") {
        if (orgName === "") {
          setOrgNameError(true);
          setOrgNameErrorText("Organisation Name is required!");
        }
        return;
      }
    },
  };

  const handleAddAddress = (newAddressValue) => {
    if (newAddressValue && newAddressValue?.formatted_address) {
      setAddress(newAddressValue.formatted_address);
      // setAddressErrors({ address: null });
    }
  };

  const handleDeleteAddress = () => {
    setAddress("");
    // setAddressErrors({ address: "Address is required!" })
  };

  const handleUpdateAddress = (newAddressValue) => {
    if (newAddressValue && newAddressValue?.formatted_address) {
      setAddress(newAddressValue.formatted_address);
      // setAddressErrors({ address: null });
    }
  };

  useEffect(() => {
    if (emailInUse) {
      setEmailError(true);
      return setEmailErrorText("Email address already in use.");
    }

    setEmailError(false);
    return setEmailErrorText("");
  }, [emailInUse]);

  useEffect(() => {
    if (phoneInUse) {
      setPhoneNumberError(true);
      return setPhoneNumberErrorText("Phone number already in use.");
    }

    setPhoneNumberError(false);
    return setPhoneNumberErrorText("");
  }, [phoneInUse]);

  useEffect(() => {
    if (
      fullName &&
      email &&
      acceptedConditions &&
      orgName &&
      !firstNameError &&
      // !lastNameError &&
      !emailError &&
      !phoneNumberError &&
      !orgNameError &&
      !abnError &&
      !acnError &&
      !aclError
      // && !addressError
    )
      return setCanSubmit(true);

    setCanSubmit(false);
  }, [
    fullName,
    email,
    orgName,
    firstNameError,
    // lastNameError,
    emailError,
    phoneNumberError,
    acceptedConditions,
    orgNameError,
    abnError,
    acnError,
    aclError,
    // addressError
  ]);

  useEffect(() => {
    // if (hasOrganisation) return navigate("/dashboard/quotes");

    if (createAccountSuccess) navigate("/organisation");
  }, [createAccountSuccess]);

  // useEffect(() => {
  //   console.log("query.get(oid)", query.get("oid"))
  //   if (query.get("oid")) setHasOrganisation(query.get("oid"));
  // });

  useEffect(() => {
    async function handleKeyDown(e) {
      if (e.keyCode === 13 && canSubmit) await handleRegistration();
    }

    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Grid
        container
        item
        height="100%"
        width="100%"
        // style={{ padding: "30px 50px 100px" }}
        justifyContent="center"
        alignItems="center"
        // direction="row-reverse"
      >
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          height="100vh"
          justifyContent="start"
          alignItems="start"
          direction="column"
          textAlign="left"
          style={{
            padding: "3vh 60px 50px",
            flexFlow: "column",
            overflow: "scroll",
          }}
        >
          <a
            href="https://financeable.com.au"
            style={{
              textDecoration: "none",
              color: "rgba(0, 0, 0, 0.87)",
            }}
          >
            <Typography
              variant="h4"
              fontWeight="800"
              style={{
                letterSpacing: "-1.8px",
              }}
            >
              Financeable
            </Typography>
          </a>
          {/* <br />
          <br /> */}
          {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
          <br />
          {/* <br /> */}
          <Typography variant="h4" fontWeight="300">
            Broker Registration
          </Typography>
          <br />
          <Typography variant="caption" fontWeight="300">
            All fields required.
          </Typography>
          <TextField
            fullWidth
            // id="outlined-basic"
            type="text"
            name="fullName"
            label="Full name*"
            autoComplete="full-name"
            variant="filled"
            // variant="outlined"
            size="small"
            value={fullName}
            inputProps={{ maxLength: 24 }}
            onChange={handleFullName}
            margin="dense"
            error={firstNameError}
            helperText={firstNameErrorText}
            placeholder="First and last name"
            onBlur={() => handle.blurFn("fullname")}
          />

          {/* <TextField
            fullWidth
            // id="outlined-basic"
            type="text"
            name="lastName"
            label="Last name"
            autoComplete="last-name"
            // variant="filled"
            // variant="outlined"
            // size="small"
            value={lastName}
            inputProps={{ maxLength: 24 }}
            onChange={handleLastName}
            margin="dense"
            helperText={lastNameErrortext}
            error={lastNameError}
          // onBlur={() => {}}
          /> */}

          <TextField
            fullWidth
            // id="outlined-basic"
            type="text"
            name="phoneNumber"
            label="Mobile number*"
            autoComplete="tel"
            variant="filled"
            // variant="outlined"
            size="small"
            value={phoneNumber}
            onChange={handlePhoneNumber}
            margin="dense"
            helperText={phoneNumberErrorText}
            error={phoneNumberError}
            onBlur={() => handle.blurFn("phoneNumber")}
            inputProps={{ maxLength: 12 }}
            placeholder="0400123123"
          />

          <TextField
            fullWidth
            shrink
            // id="outlined-basic"
            type="email"
            name="email"
            label="Email*"
            autoComplete="email"
            variant="filled"
            // variant="outlined"
            size="small"
            value={email}
            onChange={handleEmail}
            margin="dense"
            helperText={emailErrorText}
            error={emailError}
            onBlur={() => handle.blurFn("email")}
            inputProps={{ maxLength: 50 }}
          />

          <Typography style={{ marginBottom: "10px", marginTop: "50px" }}>Add Organization</Typography>
          <OrganisationSearch
            organisationDetails={organisationDetails}
            setOrganisationDetails={setOrganisationDetails}
          />
          <TextField
            fullWidth
            shrink
            // id="outlined-basic"
            type="text"
            name="orgName"
            label="Organisation Name*"
            autoComplete="Organisation Name"
            variant="filled"
            // variant="outlined"
            size="small"
            value={orgName}
            onChange={handleOrgName}
            onBlur={() => handle.blurFn("orgName")}
            margin="dense"
            helperText={orgNameErrorText}
            error={orgNameError}
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            fullWidth
            shrink
            // id="outlined-basic"
            type="text"
            name="abn"
            label="ABN"
            autoComplete="ABN"
            variant="filled"
            // variant="outlined"
            size="small"
            value={abn}
            onChange={handleABN}
            margin="dense"
            helperText={abnErrorText}
            error={abnError}
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            fullWidth
            shrink
            // id="outlined-basic"
            type="text"
            name="acn"
            label="ACN"
            autoComplete="ACN"
            variant="filled"
            // variant="outlined"
            size="small"
            value={acn}
            onChange={handleACN}
            margin="dense"
            helperText={acnErrorText}
            error={acnError}
            inputProps={{ maxLength: 50 }}
          />

          <TextField
            fullWidth
            shrink
            // id="outlined-basic"
            type="text"
            name="acl"
            label="Australian Credit Licence Number/ACR"
            autoComplete="ACL"
            variant="filled"
            // variant="outlined"
            size="small"
            value={acl}
            onChange={handleACL}
            margin="dense"
            helperText={aclErrorText}
            error={aclError}
            inputProps={{ maxLength: 50 }}
          />

          <GoogleAddressSearch
            userFieldErrors={addressErrors}
            setUserFieldErrors={setAddressErrors}
            handleCreateAddress={handleAddAddress}
            handleUpdateAddress={handleUpdateAddress}
            handleRemoveAddress={handleDeleteAddress}
            address={address}
            style={{ "margin-top": "10px" }}
          />

          {/* <TextField
            fullWidth
            shrink
            // id="outlined-basic"
            type="text"
            name="address"
            label="Address"
            autoComplete="address"
            variant="filled"
            // variant="outlined"
            size="small"
            value={address}
            onChange={handleAddress}
            margin="dense"
            helperText={addressErrorText}
            error={addressError}
            inputProps={{ maxLength: 50 }}
          /> */}

          <FormControlLabel
            style={{ margin: "20px 0 10px" }}
            control={
              <Checkbox
                checked={acceptedConditions}
                onChange={(event) => {
                  setAcceptedConditions(event.target.checked);
                }}
              />
            }
            label={
              <Typography style={{ fontSize: "14px" }}>
                I accept the{" "}
                <a
                  // onClick={() => mixpanel.track('User registration: Clicked terms and conditions')}
                  href="https://financeable.com.au/terms-conditions"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  // onClick={() => mixpanel.track('User registration: Clicked privacy policy')}
                  href="https://financeable.com.au/privacy-policy"
                >
                  Privacy Policy.
                </a>
              </Typography>
            }
          />

          <LoadingButton
            fullWidth
            // endIcon={<SendOutlinedIcon />}
            loading={isCreatingAccount}
            // loadingIndicator="Generating..."
            loadingPosition="end"
            variant="contained"
            disabled={!canSubmit}
            color="secondary"
            style={{ margin: "20px 0 10px" }}
            onClick={async () => await handleRegistration()}
          >
            Register
          </LoadingButton>
          <Typography style={{ fontSize: "14px" }}>
            Already using Financeable? <Link to="/login">Login</Link>
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          spacing={2}
          height="100vh"
          justifyContent="start"
          alignItems="center"
          direction="column"
          style={{
            marginTop: 0,
            padding: "3vh 0 0",
            // background: "linear-gradient(rgba(106,11,227,0.7), rgba(223,0,227,0.4))",
            background: "rgba(10,18,30,1)",
            // background: "rgb(10,18,30)",
            color: "#ffffff",
            textAlign: "left",
            overflow: "hidden",
          }}
        >
          <Stack
            // justifyContent="start"
            alignItems="start"
            width="80%"
            // height="100%"
          >
            {/* <Typography variant="h4" fontWeight="800" style={{ letterSpacing: "-2px", textAlign: "left", marginBottom: "30px" }}>
            Financeable
          </Typography> */}

            {/* <Typography variant="h1" fontWeight="500" style={{ textAlign: "left" }}>
            This is it.
          </Typography> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
