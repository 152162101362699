import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import OrganisationSearch from "../Utils/OrganisationSearch";
import GoogleAddressSearch from "../Utils/GoogleAddressSearchRegister";
import regex from "../../utils/regex";
import {
  createOrganisation,
  getAllParentOrganisation,
  getOrganisation,
  organisationSelector,
  updateOrganisation,
} from "../../store/slices/organisationSlice";
import { ERRORS_MESSAGE, SUCCESS_MESSAGE } from "../../constants";

const OrganisationAdd = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { parentOrganisationList } = useSelector(organisationSelector);
  const navigate = useNavigate();
  const [organisationDetails, setOrganisationDetails] = useState(null);
  const [organisationInfo, setOrganisationInfo] = useState({
    name: "",
    abn: "",
    acn: "",
    acl: "",
    phoneNumber: "",
    email: "",
    serviceInvoicePaidBy: "",
    parentOrganisation: "",
    address: "",
  });
  const [orgNameErrorText, setOrgNameErrorText] = useState("");
  const [abnErrorText, setABNErrorText] = useState("");
  const [acnErrorText, setACNErrorText] = useState("");
  const [aclErrorText, setACLErrorText] = useState("");
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [invoicePaidByErrorText, setInvoicePaidByErrorText] = useState("");
  const [addressErrors, setAddressErrors] = useState({
    address: null,
  });
  const [canSubmit, setCanSubmit] = useState(false);

  const organisationNameList = parentOrganisationList?.map((organisation) => ({
    name: organisation?.name,
    orgId: organisation?._id,
  }));

  useEffect(() => {
    dispatch(getAllParentOrganisation({}));
  }, []);

  useEffect(() => {
    if (orgId) {
      dispatch(getOrganisation(orgId)).then(({ payload }) => {
        if (payload?.data?.[0]?._id) {
          const { name, abn, acn, acl, address, email, phoneNumber, organisationsettings, parentOrganisation } =
            payload?.data?.[0];
          setOrganisationInfo({
            ...organisationInfo,
            name,
            abn,
            acn,
            acl,
            email,
            phoneNumber,
            address,
            serviceInvoicePaidBy: organisationsettings[0]?.service_invoice_paid_by,
            parentOrganisation: parentOrganisation[0]?.name,
          });
        }
      });
      return
    } else {
      setOrganisationInfo({
        name: "",
        abn: "",
        acn: "",
        acl: "",
        phoneNumber: "",
        email: "",
        serviceInvoicePaidBy: "",
        parentOrganisation: "",
        address: "",
      });
    }
  }, [orgId]);

  const handle = {
    orgName: (event) => {
      setOrganisationInfo({
        ...organisationInfo,
        name: event.target.value.trim(),
      });
      if (regex.alphaNumericAndSpecificCharOptional.test(event.target.value)) {
        return setOrgNameErrorText("");
      }

      setOrgNameErrorText("Not a valid Name.");
    },
    abn: (event) => {
      setOrganisationInfo({
        ...organisationInfo,
        abn: event.target.value.trim(),
      });
      if (regex.ABN.test(event.target.value)) {
        return setABNErrorText("");
      }

      setABNErrorText("Not a valid ABN.");
    },
    acn: (event) => {
      setOrganisationInfo({
        ...organisationInfo,
        acn: event.target.value.trim(),
      });
      if (regex.ACN.test(event.target.value)) {
        return setACNErrorText("");
      }

      setACNErrorText("Not a valid ACN.");
    },
    acl: (event) => {
      setOrganisationInfo({
        ...organisationInfo,
        acl: event.target.value.trim(),
      });
      if (regex.ACR.test(event.target.value)) {
        return setACLErrorText("");
      }

      setACLErrorText("Not a valid ACR.");
    },
    invoicePaidBy: (event) => {
      setOrganisationInfo({
        ...organisationInfo,
        serviceInvoicePaidBy: event.target.value,
      });
      if (event.target.value) {
        return setInvoicePaidByErrorText("");
      }
      setInvoicePaidByErrorText("Invoice Paid by is required.");
    },
    phoneNumber: (event) => {
      setOrganisationInfo({
        ...organisationInfo,
        phoneNumber: event.target.value.trim(),
      });
      if (/^0[45]\d{8}$/.test(event.target.value.toLowerCase())) {
        // dispatch(saveValue({ phoneInUse: false }));
        return setPhoneNumberErrorText("");
      }

      setPhoneNumberErrorText("Not a valid Australia mobile phone number.");
    },
    email: (event) => {
      setOrganisationInfo({
        ...organisationInfo,
        email: event.target.value.trim(),
      });
      if (regex.email.test(event.target.value.toLowerCase())) {
        // dispatch(saveValue({ emailInUse: false }));

        return setEmailErrorText("");
      }

      setEmailErrorText("Not a valid email address.");
    },
    // checkEmail: async () => {
    //   if (email && email.length) {
    //     await dispatch(validateEmail(email));
    //   }
    // },
    // checkPhoneNumber: async () => {
    //   if (phoneNumber && phoneNumber.length) {
    //     await dispatch(validatePhone(phoneNumber));
    //   }
    // },

    blurFn: (fieldName, value) => {
      if (fieldName === "name") {
        if (value === "") {
          setOrgNameErrorText("Organisation Name is required!");
        }
        //  else if (fieldName === "phoneNumber") {
        //   if (phoneNumber !== "") {
        //     handle.checkPhoneNumber();
        //   } else {
        //     setPhoneNumberErrorText("Phone Number is required!");
        //   }
        //   return;
        // } else if (fieldName === "email") {
        //   if (email !== "") {
        //     handle.checkEmail();
        //   } else {

        //     setEmailErrorText("Email is required!");
        //   }
        // }
        return;
      }
    },
  };

  const handleAddAddress = (newAddressValue) => {
    if (newAddressValue?.formatted_address) {
      setOrganisationInfo({
        ...organisationInfo,
        address: newAddressValue.formatted_address,
      });
      // setAddressErrors({ address: null });
    }
  };

  const handleDeleteAddress = () => {
    setOrganisationInfo({
      ...organisationInfo,
      address: "",
    });
    // setAddressErrors({ address: "Address is required!" })
  };

  const handleUpdateAddress = (newAddressValue) => {
    if (newAddressValue?.formatted_address) {
      setOrganisationInfo({
        ...organisationInfo,
        address: newAddressValue.formatted_address,
      });
      // setAddressErrors({ address: null });
    }
  };

  const handleRegistration = () => {
    const findOrg = organisationNameList?.find((org) => organisationInfo?.parentOrganisation === org.name);
    if (orgId) {
      console.log("organisationInfo", organisationInfo);
      dispatch(
        updateOrganisation({
          ...organisationInfo,
          _id: orgId,
          parentOrganisationId: organisationInfo?.parentOrganisation ? findOrg?.orgId : "",
          status: "active",
        }),
      ).then(({ payload, error }) => {
        if (error) {
          enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(payload?.status_message || SUCCESS_MESSAGE.organisationCreate, {
            variant: "success",
            autoHideDuration: 5000,
          });
          navigate("/organisation");
        }
      });
      return;
    } else {
      dispatch(
        createOrganisation({
          ...organisationInfo,
          parentOrganisation: organisationInfo?.parentOrganisation ? findOrg?.orgId : "",
          status: "active",
        }),
      ).then(({ payload, error }) => {
        if (error) {
          enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(payload?.status_message || SUCCESS_MESSAGE.organisationCreate, {
            variant: "success",
            autoHideDuration: 5000,
          });
          navigate("/organisation");
        }
      });
    }
  };

  useEffect(() => {
    if (organisationDetails) {
      setOrganisationInfo({
        ...organisationInfo,
        name: organisationDetails?.EntityName,
        abn: organisationDetails?.Abn,
        anc: organisationDetails?.Acn,
      });
      return;
    }
  }, [organisationDetails]);

  useEffect(() => {
    if (
      organisationInfo?.name &&
      organisationInfo?.abn &&
      organisationInfo?.serviceInvoicePaidBy &&
      !invoicePaidByErrorText &&
      !orgNameErrorText &&
      !abnErrorText &&
      !acnErrorText
    )
      return setCanSubmit(true);

    setCanSubmit(false);
  }, [
    organisationInfo?.name,
    orgNameErrorText,
    organisationInfo?.abn,
    abnErrorText,
    acnErrorText,
    organisationInfo?.serviceInvoicePaidBy,
    invoicePaidByErrorText,
  ]);

  useEffect(() => {
    async function handleKeyDown(e) {
      if (e.keyCode === 13 && canSubmit) await handleRegistration();
    }

    document.addEventListener("keydown", handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Typography
        variant="h1"
        style={{ fontSize: "32px", fontWeight: 800, letterSpacing: "-1.5px", marginBottom: "20px" }}
      >
        {orgId ? "Update" : "Add"} Organisation
      </Typography>
      <OrganisationSearch organisationDetails={organisationDetails} setOrganisationDetails={setOrganisationDetails} />
      <FormControl fullWidth sx={{ marginTop: "8px", marginBottom: "4px" }}>
        <InputLabel id="demo-select-small-label">Parent Organisation</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          variant="filled"
          value={organisationInfo?.parentOrganisation}
          label="Parent Organisation"
          onChange={(event) =>
            setOrganisationInfo({
              ...organisationInfo,
              parentOrganisation: event.target.value,
            })
          }
        >
          {organisationNameList?.map((item) => (
            <MenuItem value={item.name}>{item.name.toUpperCase()}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        shrink
        type="text"
        name="orgName"
        required
        label="Organisation Name"
        autoComplete="Organisation Name"
        variant="filled"
        size="small"
        value={organisationInfo?.name}
        onChange={handle.orgName}
        onBlur={(event) => handle.blurFn("name", event.target.value)}
        margin="dense"
        helperText={orgNameErrorText}
        error={orgNameErrorText}
        inputProps={{ maxLength: 50 }}
      />
      <TextField
        fullWidth
        shrink
        type="text"
        name="abn"
        label="ABN"
        required
        autoComplete="ABN"
        variant="filled"
        size="small"
        value={organisationInfo?.abn}
        onChange={handle.abn}
        margin="dense"
        helperText={abnErrorText}
        error={abnErrorText}
        inputProps={{ maxLength: 50 }}
      />
      <TextField
        fullWidth
        shrink
        type="text"
        name="acn"
        label="ACN"
        autoComplete="ACN"
        variant="filled"
        size="small"
        value={organisationInfo?.acn}
        onChange={handle.acn}
        margin="dense"
        helperText={acnErrorText}
        error={acnErrorText}
        inputProps={{ maxLength: 50 }}
      />
      <TextField
        fullWidth
        shrink
        type="text"
        name="acl"
        label="Australian Credit License Number/ACR"
        autoComplete="ACL"
        variant="filled"
        size="small"
        value={organisationInfo?.acl}
        onChange={handle.acl}
        margin="dense"
        helperText={aclErrorText}
        error={aclErrorText}
        inputProps={{ maxLength: 50 }}
      />
      <FormControl fullWidth sx={{ marginTop: "8px", marginBottom: "4px" }}>
        <InputLabel id="demo-simple-select-label" required>
          Invoice paid by
        </InputLabel>
        <Select
          variant="filled"
          id="demo-simple-select"
          value={organisationInfo?.serviceInvoicePaidBy || ""}
          label="Invoice paid by"
          error={invoicePaidByErrorText}
          onChange={handle.invoicePaidBy}
        >
          {["ORGANISATION", "USER"].map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        {invoicePaidByErrorText && <FormHelperText sx={{ color: "#d32f2f" }}>{invoicePaidByErrorText}</FormHelperText>}
      </FormControl>
      <TextField
        fullWidth
        shrink
        type="email"
        name="email"
        label="Email"
        autoComplete="email"
        variant="filled"
        size="small"
        value={organisationInfo?.email}
        onChange={handle.email}
        margin="dense"
        helperText={emailErrorText}
        error={emailErrorText}
        onBlur={(event) => handle.blurFn("email", event.target.value)}
        inputProps={{ maxLength: 50 }}
      />

      <TextField
        fullWidth
        type="text"
        name="phoneNumber"
        label="Mobile number"
        autoComplete="tel"
        variant="filled"
        size="small"
        value={organisationInfo?.phoneNumber}
        onChange={handle.phoneNumber}
        margin="dense"
        helperText={phoneNumberErrorText}
        error={phoneNumberErrorText}
        onBlur={(event) => handle.blurFn("phoneNumber", event.target.value)}
        inputProps={{ maxLength: 12 }}
        placeholder="0400123123"
      />
      {orgId && organisationInfo?.address && (
        <GoogleAddressSearch
          userFieldErrors={addressErrors}
          setUserFieldErrors={setAddressErrors}
          handleCreateAddress={handleAddAddress}
          handleUpdateAddress={handleUpdateAddress}
          handleRemoveAddress={handleDeleteAddress}
          address={organisationInfo?.address}
          style={{ "margin-top": "10px" }}
          isOrg={true}
        />
      )}

      {!organisationInfo?.address && (
        <GoogleAddressSearch
          userFieldErrors={addressErrors}
          setUserFieldErrors={setAddressErrors}
          handleCreateAddress={handleAddAddress}
          handleUpdateAddress={handleUpdateAddress}
          handleRemoveAddress={handleDeleteAddress}
          address={organisationInfo?.address}
          style={{ "margin-top": "10px" }}
        />
      )}

      <LoadingButton
        fullWidth
        loadingPosition="end"
        variant="contained"
        disabled={!canSubmit}
        color="secondary"
        style={{ margin: "20px 0 10px" }}
        onClick={async () => await handleRegistration()}
      >
        {orgId ? "Update" : "Create"} Organisation
      </LoadingButton>
    </>
  );
};

export default OrganisationAdd;
