import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { debounce } from "lodash";
import { ERRORS_MESSAGE } from "../../constants";
import regex from "../../utils/regex";
import { addPhoneNumber, setRequiredFieldsErrors } from "../../store/slices/applicationFormSlice";
import { customerSelector } from "../../store/slices/customerSlice";

export default function PhoneNumber({
  phoneNumber,
  disabled,
  label,
  fieldKey,
  saveContactNum,
  customerId,
  singleCustomerId,
  guarantorFieldErrors,
  setGuarantorFieldErrors,
  applicationId,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isCustomerLogin } = useSelector(customerSelector);

  const [allValues, setAllValue] = useState({
    number: phoneNumber?.number || phoneNumber,
  });

  // const [fieldErrors, setFieldErrors] = useState({
  //   number: "",
  // });

  useEffect(() => {
    if (phoneNumber?.number) {
      setAllValue({ number: phoneNumber?.number })
    } else if (phoneNumber) {
      setAllValue({ number: phoneNumber })
    }else{
      setAllValue({ number: '' })
    }
  }, [phoneNumber])

  const validationSchema = Yup.object({
    number: Yup.string()
      .required("number field is required")
      .matches(regex.phoneNumber, "Enter a valid Australian landline number."),
  });

  const debouncedValidation = useCallback(
    debounce(async (name, fieldName, value) => {
      try {
        if (fieldKey === "phoneNumberValue") {
          await validationSchema.validateAt("number", { number: value });
        } else {
          await validationSchema.validateAt(name, { [name]: value });
        }
        // setGuarantorFieldErrors((prevErrors) => ({
        //   ...prevErrors,
        //   Number: "",
        // }));
  
        if (fieldKey === "phoneNumberValue") {
          dispatch(
            setRequiredFieldsErrors({
              ...guarantorFieldErrors,
              [fieldName]: "",
            }),
          );
        } else {
          setGuarantorFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
        }
      } catch (error) {
  
        if (fieldKey === "phoneNumberValue") {
          dispatch(
            setRequiredFieldsErrors({
              ...guarantorFieldErrors,
              [fieldName]: error.message,
            }),
          );
        } else {
          setGuarantorFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: error.message,
          }));
        }
      }
    }, 300),
    []
  );

  const handle = {
    onChangeField: async (e, nameField) => {
      const name = nameField.split("_")[0];
      if (name === "number" || name === "phoneNumber") {
        // setAllValue({ ...allValues, [name]: e.replace(/\s+/g, "") });
        setAllValue({ ...allValues, number: e.replace(/\s+/g, "") });
      } else {
        setAllValue({ ...allValues, [name]: e });
      }
      // await validateField(name, nameField, e);
      debouncedValidation(name, nameField, e);
    },
    blurFn: (fieldName, value) => {
      try {
        // const fieldName = field.split("_")[0];
        const inputValue = value.replace(/\s+/g, ""); // trim() not work
        if (phoneNumber?.number === inputValue) {
          return;
        }
        let fieldErr = guarantorFieldErrors[fieldName] || "";

        const isValid = regex.phoneNumber.test(inputValue)

        if (isValid) {
          fieldErr = "";
        } else {
          fieldErr = "Enter a valid Australian landline number.";
        }

        // if (fieldErrors[fieldName] === "") {
        if (fieldErr === "") {
          if (fieldKey === "supplierPhone") {
            saveContactNum("supplierPhone", inputValue);
            return;
          }

          if (fieldKey === "phoneNumberValue") {
            saveContactNum("phoneNumber", inputValue);
            return;
          }

          if (fieldKey !== "supplierPhone" && label) {
            saveContactNum("contactNumber", inputValue);
            return;
          }

          dispatch(
            addPhoneNumber({
              isCustomerLogin,
              data: {
                phoneNumberId: phoneNumber?._id,
                customerId,
                number: inputValue,
                applicationId,
              },
            }),
          ).then((res) => {
            if (res?.payload?.data?.data) {
              // enqueueSnackbar(SUCCESS_MESSAGE.applicationCreateSuccessMsg, {
              //   variant: "success",
              //   autoHideDuration: 5000,
              // });
            } else {
              enqueueSnackbar(res?.payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
                variant: "error",
                autoHideDuration: 5000,
              });
            }
          });
        }
      } catch (error) {
        const newErrors = {};
        error.inner.forEach((validationError) => {
          newErrors[validationError.path] = validationError.message;
        });
        console.log("newErrors", newErrors);
        // setFieldErrors(newErrors);
      }
    },
  };

  return (
    <InputMask
      mask={fieldKey === "phoneNumberValue" ? "9999999999" : "9999 999 999"}
      value={allValues?.number || ""}
      disabled={disabled || false}
      // onChange={(event) => handle.onChangeField(event?.target?.value, `number_${customerId}`)}
      onChange={(event) =>
        handle.onChangeField(
          event?.target?.value,
          fieldKey === "phoneNumberValue" ? `phoneNumber_${customerId}_0` : `number_${customerId}`,
        )
      }
      // onBlur={(e) => handle.blurFn(`number_${customerId}`, e?.target?.value)}
      onBlur={(e) =>
        handle.blurFn(
          fieldKey === "phoneNumberValue" ? `phoneNumber_${customerId}_0` : `number_${customerId}`,
          e?.target?.value,
        )
      }
      maskChar=""
    >
      {() => (
        <TextField
          id="outlined-basic"
          label={label || "Mobile number"}
          variant="filled"
          size="small"
          disabled={disabled || false}
          fullWidth
          name={`number_${customerId}`}
          // name={fieldKey === "phoneNumberValue" ? `phoneNumber_${customerId}` : `number_${customerId}`}
          // error={guarantorFieldErrors[`number_${customerId}`]} // guarantorFieldErrors?.Number
          error={
            fieldKey === "phoneNumberValue"
              ? guarantorFieldErrors[`phoneNumber_${customerId}_0`]
              : guarantorFieldErrors[`number_${customerId}`]
          } // guarantorFieldErrors?.Number
          // helperText={guarantorFieldErrors[`number_${customerId}`]} // guarantorFieldErrors?.Number
          helperText={
            fieldKey === "phoneNumberValue"
              ? guarantorFieldErrors[`phoneNumber_${customerId}_0`]
              : guarantorFieldErrors[`number_${customerId}`]
          } // guarantorFieldErrors?.Number
        />
      )}
    </InputMask>
  );
}
