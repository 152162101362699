import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { Permission } from "./Permission";
import { ERRORS_MESSAGE, STATUS_CODE, cellAlign } from "../../../constants";
import {
  getAllRoleDetail,
  getOrganisationPermissionById,
  getRoleById,
  saveRolePermissions,
  userSelector,
} from "../../../store/slices/userSlice";
import ConfirmModal from "../../../components/customComponents/ConfirmModal";
import { TableLoader } from "../../../components/customComponents/TableLoader";

const RolePermission = () => {
  const dispatch = useDispatch();
  const { organisationId } = useParams();
  const [expand, setExpand] = useState({});
  const [permissionInfo, setPermissionInfo] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { userRoleList, userRoleLoader, getRoleByIdLoader, roleById, oranganisationName } = useSelector(userSelector);

  useEffect(() => {
    if (organisationId) {
      dispatch(getOrganisationPermissionById({ _id: organisationId }));
      return;
    }
    dispatch(getAllRoleDetail());
  }, [organisationId]);

  const handle = {
    editPermissions: (userType, roleId) => {
      dispatch(getRoleById({ _id: roleId })).then(({ payload }) => {
        const updatedServicesAccess = { All: false, ...payload?.data?.services };
        const isAllChecked = Object.values(payload?.data?.services).every((value) => value);
        updatedServicesAccess["All"] = isAllChecked;
        const updatedLendersAccess = { All: false, ...payload?.data?.lenders };
        const isAllLendersChecked = Object.values(payload?.data?.lenders).every((value) => value);
        updatedLendersAccess["All"] = isAllLendersChecked;
        const { access, ...withoutAccessApplicationAccess } = payload?.data?.application;
        const newUserAccessList = Object.fromEntries(
          Object.entries(payload?.data?.user).filter(([key]) => key !== "access"),
        );
        setPermissionInfo({
          ...permissionInfo,
          applicationAccesses: withoutAccessApplicationAccess,
          applicationAccess: payload?.data?.application?.access,
          userAccesses: newUserAccessList,
          userAccess: payload?.data?.user?.access,
          userSettingsAccess: payload?.data?.billing?.all,
          servicesAccess: updatedServicesAccess,
          lendersAccess: updatedLendersAccess,
          organisationAccess: payload?.data?.org,
        });
      });
      setExpand({
        [userType]: !expand[userType],
      });
    },
    savePermissions: (userType, orgId, roleId) => {
      const { All, ...UpdateServicesAccess } = { ...permissionInfo?.servicesAccess };
      const { AllLenders, ...UpdateLendersAccess } = { ...permissionInfo?.lendersAccess };
      const allApplicationAccess = {
        ...permissionInfo?.applicationAccesses,
        access: permissionInfo?.applicationAccess,
      };
      const allUserAccess = { ...permissionInfo?.userAccesses, access: permissionInfo?.userAccess };
      const data = {
        data: {
          permission: {
            application: allApplicationAccess,
            billing: { all: permissionInfo?.userSettingsAccess },
            services: UpdateServicesAccess,
            lenders: UpdateLendersAccess,
            user: allUserAccess,
            org:permissionInfo?.organisationAccess
          },
          userType: userType,
        },
        id: roleId,
      };
      dispatch(saveRolePermissions(data)).then(({ payload }) => {
        if (payload?.status_code === STATUS_CODE.success) {
          setExpand({
            [userType]: !expand[userType],
          });
          enqueueSnackbar(payload?.status_message, {
            variant: "success",
            autoHideDuration: 5000,
          });
        } else {
          enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
            variant: "error",
            autoHideDuration: 5000,
          });
        }
      });
    },
  };

  const saveOrganizationPermissions = ({ userType, orgId, roleId, isSave }) => {
    const { All, ...UpdateServicesAccess } = { ...permissionInfo?.servicesAccess };
    const { AllLenders, ...UpdateLendersAccess } = { ...permissionInfo?.lendersAccess };
    const allApplicationAccess = {
      ...permissionInfo?.applicationAccesses,
      access: permissionInfo?.applicationAccess,
    };
    const allUserAccess = { ...permissionInfo?.userAccesses, access: permissionInfo?.userAccess };
    const data = {
      data: {
        permission: {
          application: allApplicationAccess,
          billing: { all: permissionInfo?.userSettingsAccess },
          services: UpdateServicesAccess,
          lenders: UpdateLendersAccess,
          user: allUserAccess,
          org:permissionInfo?.organisationAccess
        },
        userType: userType,
        isUpdateOrgUserPermission: isSave,
      },
      id: roleId,
    };

    dispatch(saveRolePermissions(data)).then(({ payload }) => {
      if (payload?.status_code === STATUS_CODE.success) {
        setExpand({
          [userType]: !expand[userType],
        });
        enqueueSnackbar(payload?.status_message, {
          variant: "success",
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar(payload?.status_message || ERRORS_MESSAGE.fetchErrorMsg, {
          variant: "error",
          autoHideDuration: 5000,
        });
      }
    });
  };

  return (
    <>
      {organisationId && (
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            fontWeight: 800,
            letterSpacing: "-0.5px",
          }}
          mb={"10px"}
        >
          Role & Permissions - {oranganisationName}
        </Typography>
      )}
      <Grid container spacing={1.5} direction={"column"} width={"100%"}>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {userRoleLoader && TableLoader({ columnSpan: 5 })}
                {!userRoleList || (userRoleList?.length === 0 && !userRoleLoader) ? (
                  <TableRow>
                    <TableCell align={cellAlign} colSpan={5}>
                      No Record Found
                    </TableCell>
                  </TableRow>
                ) : (
                  userRoleList.map((role) => (
                    <>
                      <TableRow
                        key={role?._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="left" colSpan={4} sx={{ fontSize: "1rem" }}>
                          <Typography variant="h6">{role?.userType}</Typography>
                        </TableCell>

                        <TableCell align={"right"}>
                          {expand[role?.userType] ? (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() =>
                                organisationId
                                  ? setIsOpen({
                                      isOpen: true,
                                      roleInfo: {
                                        userType: role?.userType,
                                        orgId: role?.organisation?._id || role?.organisation,
                                        roleId: role?._id,
                                      },
                                    })
                                  : handle.savePermissions(
                                      role?.userType,
                                      role?.organisation?._id || role?.organisation,
                                      role?._id,
                                    )
                              }
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => handle.editPermissions(role?.userType, role?._id)}
                            >
                              Edit
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                      {expand[role?.userType] && getRoleByIdLoader && TableLoader({ columnSpan: 5 })}
                      {expand[role?.userType] && roleById?.length === 0 && !getRoleByIdLoader ? (
                        <TableRow>
                          <TableCell align={cellAlign} colSpan={5}>
                            No Record Found
                          </TableCell>
                        </TableRow>
                      ) : (
                        expand[role?.userType] && (
                          <TableRow>
                            <TableCell colSpan={5}>
                              {permissionInfo?.applicationAccesses && (
                                <Permission permissionInfo={permissionInfo} setPermissionInfo={setPermissionInfo} />
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <ConfirmModal
        isOpen={isOpen}
        handle={saveOrganizationPermissions}
        setIsOpen={setIsOpen}
        title={"confirm"}
        btnTextNo="No"
        btnTextYes="Yes"
        message={"Are you sure you want to update your organisation's user permissions ?"}
      />
    </>
  );
};

export default RolePermission;
