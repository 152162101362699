import { Fragment, useCallback, useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, InputAdornment, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import * as Yup from "yup";
import { debounce } from "lodash";
import { dollarStringOptions } from "../../../constants";
import { NumericFormatCustom } from "../../../utils/currencyMaskFormat";
import regex from "../../../utils/regex";
// import { handleMixpanelQuoteEvent } from "../../../utils/mixPanelTrackEvents";
import {
  loanDetailsSelector,
  updateLoanDetails,
  saveLoanDetails,
  assetSelector,
} from "../../../store/slices/applicationFormSlice";

const Balloon = ({ disabled }) => {
  const dispatch = useDispatch();
  // const { applicationId } = useParams();
  const asset = useSelector(assetSelector);
  const { assetValue } = asset;

  const loanDetails = useSelector(loanDetailsSelector);
  // const [balloonValue, setBalloonValue] = useState(balloon);
  const [mode, setMode] = useState({ balloon: "percentage" });
  const isBallonPerCheck = mode.balloon === "percentage";

  const {
    _id,
    balloon = 0,
    balloonAmount = 0,
    tradeIn = 0,
    payout = 0,
    deposit = 0,
  } = loanDetails;

  const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;

  const [allValues, setAllValue] = useState({
    balloon: balloon || 0,
    balloonAmount: balloonAmount || 0,
  });

  useEffect(() => {
    setAllValue({
      balloon: balloon || 0,
      balloonAmount: balloonAmount || 0,
    })
  }, [loanDetails?.balloon])


  const [fieldErrors, setFieldErrors] = useState({
    balloon: "",
    balloonAmount: "",
  });

  const validationSchema = Yup.object({
    balloon: Yup.string()
      .optional()
      .matches(regex.percentageRegex, "Please enter valid percentage.")
      .matches(regex.percentageRegex, {
        message: "Percentage should be upto 100%.",
      }),
    balloonAmount: Yup.string()
      .optional()
      .matches(regex.allowNumberWithZero, "Only numbers and decimal points allowed.")
      .matches(regex.allowNumberAndDecimal, {
        message: "Number must be to up 7 digit and up to 3 decimal places",
      })
      .max(10, "Maximum of 7 digits"),
  });

  const debouncedValidation = useCallback(
    debounce(async (fieldName, value) => {
      try {
        await validationSchema.validateAt(fieldName, { [fieldName]: value });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));
      } catch (error) {
        setFieldErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: error.message,
        }));
      }
    }, 300),
    []
  );

  const handleModeChange = (key, newMode) => {
    if (newMode !== null) {
      if (key === "balloon") {
        if (mode.balloon !== "percentage") {
          debouncedValidation("balloon", allValues.balloon);
        } else {
          debouncedValidation("balloonAmount", parseFloat(allValues.balloonAmount?.toFixed(2)));
        }
      }
      setMode({
        ...mode,
        [key]: newMode
      });
    }
  };

  const handle = {
    onChangeField: async (e, name) => {
      let isValid = true;

      if (e === "") {
        setAllValue({ ...allValues, [name]: e });
        setFieldErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        return;
      }


      if (!isValid) {
        debouncedValidation(name, e);
        return;
      }

      if (name === "balloon") {
        if (mode.balloon === "percentage") {
          if (e < 0) {
            debouncedValidation(name, e);
            return;
          }
          setAllValue({ ...allValues, [name]: +e, balloonAmount: handle.calcBalloonAmount(e) });
          debouncedValidation(name, e);
          return
        } else {
          setAllValue({ ...allValues, [name]: handle.calcBalloonPercentage(e), balloonAmount: Math.abs(e) });
        }
      } else {
        setAllValue({ ...allValues, [name]: e });
        debouncedValidation(name, e);
      }
    },
    calcBalloonAmount: (balloon) => {
      const netAssetValue = assetValue * 1 - deposit * 1 - tradeIn * 1 + payout * 1;
      const balloonAmount = netAssetValue * balloon / 100
      return balloonAmount;
    },
    calcBalloonPercentage: (balloonAmount) => {
      const balloonPercentage = balloonAmount / netAssetValue * 100;
      return balloonPercentage && !isNaN(balloonPercentage) && isFinite(balloonPercentage)
        ? balloonPercentage
        : 0;
    },
    loanDetailsFn: (value) => {
      if (loanDetails?.balloon !== value?.balloon && loanDetails?.balloonAmount !== value?.balloonAmount) {
        dispatch(saveLoanDetails(value));
        dispatch(updateLoanDetails({ _id, ...value }));
      }
    },
  }

  // const handleBalloon = async (value) => {
  //   dispatch(saveLoanDetails({ balloon: value }));
  //   dispatch(updateLoanDetails(
  //     { balloon: value, _id })
  //   )
  // };

  return (
    <Fragment>

      {/* <Typography variant="subtitle2">Balloon {balloonValue}%</Typography>
    <Box style={{ padding: "0 7px" }}>
      <Slider
        color="primary"
        size="small"
        marks={[
          { value: 0, label: 0 },
          { value: 10, label: 10 },
          // { value: 15, label: 15 },
          { value: 20, label: 20 },
          // { value: 25, label: 25 },
          { value: 30, label: 30 },
          // { value: 35, label: 35 },
          { value: 40, label: 40 },
        ]}
        step={5}
        min={0}
        max={40}
        value={balloonValue || balloon || 0}
        onChange={(event) => {
          setBalloonValue(event.target.value)
        }}
        onChangeCommitted={() => {
          handleMixpanelQuoteEvent(applicationId, "balloon", balloonValue)
          handleBalloon(balloonValue)
        }}
        aria-label="Default"
        valueLabelDisplay="auto"
        disabled={disabled}
      />
    </Box> */}

      <Stack direction="column">
        <Typography variant="caption">
          Balloon {!isNaN(allValues?.balloon) ? Math.round(allValues?.balloon * 100) / 100 : 0}% |{" "}
          {(allValues?.balloonAmount ? allValues?.balloonAmount : 0).toLocaleString(
            "en-US",
            dollarStringOptions,
          )}
        </Typography>
        <Box display="flex" alignItems="center">
          <TextField
            label="Balloon"
            name="balloon"
            disabled={disabled}
            value={isBallonPerCheck ? allValues?.balloon : allValues?.balloonAmount}
            error={isBallonPerCheck ? fieldErrors?.balloon : fieldErrors?.balloonAmount}
            helperText={isBallonPerCheck ? fieldErrors?.balloon : fieldErrors?.balloonAmount}
            onChange={(event) => {
              const value = event.target.value;
              // Handle clearing the value properly
              handle.onChangeField(value || 0, "balloon");
            }}
            onBlur={() => {
              // console.log("onBlur", {
              //   balloon: parseFloat(allValues.balloon?.toFixed(2))  || 0,
              //   balloonAmount: parseFloat(allValues.balloonAmount?.toFixed(2)) || 0,
              // })
              handle.loanDetailsFn({
                balloon: parseFloat(allValues.balloon?.toFixed(2)) || 0,
                balloonAmount: parseFloat(allValues.balloonAmount?.toFixed(2)) || 0,
              });
            }}
            InputProps={{
              inputComponent: NumericFormatCustom,
              inputProps: {
                prefix: "",
                isAllowedFlag: true,
                maxDigits: 7,
                maxPlaces: 2,
              },
              startAdornment: allValues?.balloonAmount > 0 && mode.balloon !== "percentage" && (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <ToggleButtonGroup
                    value={mode.balloon}
                    exclusive
                    color="primary"
                    onChange={(event, value) => handleModeChange("balloon", value)}
                    sx={{
                      mr: -1,
                      height: "36px",
                    }}
                  >
                    <ToggleButton value="percentage">%</ToggleButton>
                    <ToggleButton value="dollar">$</ToggleButton>
                  </ToggleButtonGroup>
                </InputAdornment>
              ),
            }}
            type="text"
            size="small"
            variant="filled"
            fullWidth
          />
        </Box>
      </Stack>
    </Fragment>
  )
}

export default Balloon