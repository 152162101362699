import React, { useEffect, useState } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import PrefixTextField from './common/PrefixTextField'
import CalCustomTitleBar from './common/CalCustomTitleBar'
import { defaultRevenueFeesList } from '../../constants'

const CalRevenue = React.memo(({ allValues, calculatorFieldsErrors, validateField, saveLoanDetails, setAllValue }) => {
  const [feesList, setFeesList] = useState(defaultRevenueFeesList)

  const handle = {
    onChangeField: async (e, name, key) => {
      let updateField = [];
      updateField = feesList?.map((obj) => {
        if (obj.name === name) {
          return { ...obj, value: +e };
        }
        return obj;
      });

      setAllValue({
        ...allValues,
        fees: updateField,
      });
      return;

    },
    blurFn: (fieldName, value, key) => {
      try {
        saveLoanDetails({
          fees: allValues?.fees,
        });
      } catch (error) {
        console.log("error", error)
        // const newErrors = {};
        // error.inner.forEach((validationError) => {
        //   newErrors[validationError.path] = validationError.message;
        // });
        // // setFieldErrors(newErrors);
      }
    },
  }

  useEffect(() => {
    if (allValues?.fees?.length > 0) {
      setFeesList(allValues?.fees)
    } else {
      setFeesList(defaultRevenueFeesList)
    }
    // setFeesList(allValues?.fees)

  }, [allValues?.fees])

  return (
    <Box>
      <CalCustomTitleBar title={"Revenue (Fees)"} />

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        mb={"20px"}
        spacing={1}
        direction="row"
      // sx={{ borderBottom: "1px solid rgba(0,0,0,0.12)" }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }} mb={"5px"} mt={"5px"}>
            Lender
          </Typography>
          {feesList?.map((fee, index) =>
            fee?.key === "fee" && (
              <Stack key={fee?.name || index}>
                <PrefixTextField
                  name={fee?.name}
                  label={fee?.label}
                  value={fee?.value || ""}
                  changeHandler={(value, name) => handle.onChangeField(value, name, fee?.name)}
                  blurHandler={(name, value) => handle.blurFn(name, value, fee?.name)}
                />
              </Stack>
            )
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }} mb={"5px"} mt={"5px"}>
            Broker
          </Typography>
          {feesList?.map((fee, index) =>
            fee?.key === "brokerage" && (
              <Stack key={fee?.name || index}>
                <PrefixTextField
                  name={fee?.name}
                  label={fee?.label}
                  value={fee?.value || ""}
                  changeHandler={(value, name) => handle.onChangeField(value, name, fee?.name)}
                  blurHandler={(name, value) => handle.blurFn(name, value, fee?.name)}
                />
              </Stack>
            )
          )}
        </Grid>
      </Grid>
    </Box>
  )
})

export default CalRevenue