import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, Grid, IconButton, Input, InputAdornment, Stack, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import clsx from "clsx";
import { debounce } from "lodash";
import { userSelector } from "../../store/slices/userSlice";
import { getAllOrganisation, organisationsSelector } from "../../store/slices/organisationSlice";
import { useStyles } from "../User/UserList";

const OrganisationList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { _id, permissions } = useSelector(userSelector);
  const organisations = useSelector(organisationsSelector);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowCountState, setRowCountState] = useState(organisations?.totalDocs || 0);

  const columns = [
    {
      field: "date",
      headerName: "Date",
      type: "string",
      width: 150,
      renderCell: (params) => (
        <div>
          <Typography style={{ fontSize: "14px" }}>{params.value.date}</Typography>
          <Typography style={{ fontSize: "11px", color: "#999" }}>{params.value.time}</Typography>
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 270,
      // editable: true,
    },
    {
      field: "acn",
      headerName: "ACN",
      width: 150,
      // editable: true,
    },
    {
      field: "abn",
      headerName: "ABN",
      width: 150,
      // editable: true,
    },
    {
      field: "acl",
      headerName: "ACL",
      width: 150,
      // editable: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 350,
      // editable: true,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <Button onClick={() => navigate(`/organisation/rolepermissions/${params?.id}`)} variant="outlined">
          edit Permission
        </Button>
      ),
    },
    {
      field: "update",
      headerName: "",
      sortable: false,
      width: 300,
      renderCell: (params) => (
        <Button onClick={() => navigate(`/organisation/${params?.id}`)} variant="outlined">
          update organisation detail
        </Button>
      ),
    },
  ];

  const handlePageChange = (newPage) => {
    handle.getAllOrganisation(newPage + 1, 10);
    setPage(newPage);
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      organisations?.totalDocs !== undefined ? organisations?.totalDocs : prevRowCountState,
    );
  }, [organisations?.totalDocs, setRowCountState]);

  const handle = {
    getAllOrganisation: (cursor, limit, search) => {
      dispatch(getAllOrganisation({ cursor, limit, search }));
    },
    handleUserSearch: (searchTerm) => {
      (searchTerm.length >= 2 || searchTerm.length <= 0) &&
        handle.getAllOrganisation(organisations?.page, 10, searchTerm);
    },
  };

  useEffect(() => {
    if (_id) handle.getAllOrganisation(organisations?.page || 1, 10);
  }, [_id]);

  useEffect(() => {
    setRows(
      organisations?.docs?.map((doc) => {
        const date = new Date(doc.createdAt);
        return {
          id: doc._id,
          name: doc.name,
          acn: doc.acn,
          abn: doc.abn,
          acl: doc.acl,
          address: doc.address,
          date: {
            date: moment(date).format("D-M-YY"),
            time: moment(date).format("h:mm a"),
          },
        };
      }),
    );
  }, [organisations]);

  const debounced = useCallback(debounce(handle.handleUserSearch, 500), []);

  return (
    <Grid
      container
      item
      direction="column"
      spacing={0}
      style={{
        // marginTop: "64px",
        padding: "20px",
      }}
    >
      <Grid
        container
        item
        justifyContent={"space-between"}
        style={{
          paddingBottom: "5px",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Grid
          item
          style={{
            // borderBottom: "1px solid rgba(224, 224, 224, 1)",
            display: "flex",
            alignItems: "center",
            columnGap: "40px",
          }}
        >
          <Typography
            variant="h1"
            style={{
              fontSize: "24px",
              fontWeight: 800,
              letterSpacing: "-0.5px",
            }}
          >
            Organisations
          </Typography>
          <Stack>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <Input
                className="input-border-bottom"
                id="user-search"
                autoComplete="search" // false
                variant="filled"
                placeholder="Search…"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  debounced(e.target.value, 1000);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchTerm && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearchTerm("");
                          searchTerm !== "" && debounced("", 1000);
                        }}
                        color="secondary"
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Stack>
        </Grid>
      </Grid>

      <Grid item>
        <DataGrid
          autoHeight
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          onPageChange={handlePageChange}
          rows={rows || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          columnVisibilityModel={{ action: !!permissions?.org?.permission_edit, update: !!permissions?.org?.edit }}
          disableSelectionOnClick
          style={{ background: "#ffffff", border: "none" }}
          rowCount={rowCountState}
          paginationMode="server"
          page={page}
          sx={{
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
              backgroundColor: "#999",
            },
            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
              background: "#616161",
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OrganisationList;
