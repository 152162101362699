import React from "react";

import { useDispatch } from "react-redux";
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Grid,
  Typography,
  Switch,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { round, merge } from "lodash";
import { sendDisclosure, updateLoanDetails } from "../../store/slices/applicationFormSlice";
import { dollarStringOptions } from "../../constants";
import { isValidNumberWithDecimalInput } from "../Utils/helperFunction";

const ConnectiveSendDisclosure = ({
  productDetails,
  application,
  handleOpenDisclosureDialog,
  handleCloseDisclosureDialog,
  disclosureFields,
  setDisclosureFields,
  openDisclosureDialog,
  updateDisclosureRate,
  loanDetails,
}) => {
  const dispatch = useDispatch();

  const handle = {
    onChangeField: (value, name) => {
      let isValid = true

      if (value === "") {
        setDisclosureFields({
          ...disclosureFields,
          [name]: "",
        });
        return;
      }

      if (
        name === "originationFee" ||
        name === "applicationFee" ||
        name === "establishmentFee" ||
        name === "referralFee"
      ) {
        isValid = isValidNumberWithDecimalInput(value)
      }

      if (isValid) {
        setDisclosureFields({
          ...disclosureFields,
          [name]: value,
        });
      }
    },
    saveQuote: async () => {
      if (loanDetails?._id)
        await dispatch(
          updateLoanDetails(
            merge({
              ...loanDetails,
              ...{
                loanAmount: round(productDetails?.loanAmount, 2),
                rate: productDetails?.rate,
                repayments: round(productDetails?.repayments, 2),
                brokerage: productDetails?.brokerage || 0,
                brokerageAmount: round(productDetails?.brokerageAmount, 2) || 0,
                // fees: handleFees(),
              },
            }),
          ),
        ).unwrap();
    },
  };

  return (
    <React.Fragment>
      <Dialog open={openDisclosureDialog} onClose={handleCloseDisclosureDialog}>
        <DialogTitle>Send Proposal Disclosure Statement</DialogTitle>
        <DialogContent>
          <DialogContentText>Please review and update the details below.</DialogContentText>
          <Stack direction="column">
            <TextField
              margin="dense"
              size="small"
              label="Lender Name"
              disabled
              variant="outlined"
              value={productDetails?.lender}
            />
            {/* <TextField
              autoFocus
              margin="dense"
              size="small"
              label="Repayment Amount"
              variant="outlined"
              value={disclosureFields.repayments}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  repayments: e.target.value,
                });
              }}
            /> */}

            <TextField
              margin="dense"
              size="small"
              label="Interest Rate"
              variant="outlined"
              value={disclosureFields.rate}
              onChange={(e) => {
                // setDisclosureFields({
                //   ...disclosureFields,
                //   rate: e.target.value,
                // });
                updateDisclosureRate({ rate: e.target.value });
              }}
            />

            {/* <TextField
              margin="dense"
              size="small"
              id="rate"
              label="Effective Rate"
              variant="outlined"
              value={disclosureFields.effectiveRate}
              onChange={(e) => {
                setDisclosureFields({
                  ...disclosureFields,
                  effectiveRate: e.target.value,
                });
              }}
            /> */}

            <TextField
              margin="dense"
              size="small"
              label="Establishment Fee"
              variant="outlined"
              value={disclosureFields.establishmentFee}
              onChange={(e) => {
                handle.onChangeField(e.target.value, "establishmentFee")
              }}
            />

            <TextField
              margin="dense"
              size="small"
              label="Doc Fee"
              variant="outlined"
              value={disclosureFields.applicationFee}
              onChange={(e) => {
                handle.onChangeField(e.target.value, "applicationFee")
              }}
            />
            <TextField
              margin="dense"
              size="small"
              label="Origination fee"
              variant="outlined"
              value={disclosureFields.originationFee}
              onChange={(e) => {
                handle.onChangeField(e.target.value, "originationFee")
              }}
            />
            <TextField
              margin="dense"
              size="small"
              label="Referer Fee"
              variant="outlined"
              value={disclosureFields.referralFee}
              onChange={(e) => {
                handle.onChangeField(e.target.value, "referralFee")
              }}
            />

            <FormControl component="fieldset" variant="standard" sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event) => {
                      updateDisclosureRate({ rate: disclosureFields?.rate?.toString(), inArrears: event.target?.checked ? "Yes" : "No" });
                    }}
                    checked={disclosureFields?.inArrears === "Yes" ? true : false}
                    name={"In arrears"}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                }
                label={"In arrears"}
              />
            </FormControl>

            {/* Repayments */}
            <Grid item sm={12} spacing={1} direction="row" style={{ margin: "15px 0px 0px 0px" }}>
              <Typography
                sx={{ display: "inline" }}
                variant="body2"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 5px 3px 0",
                }}
              >
                <Typography variant="body2" fontWeight={"bold"}>
                  Repayments:
                </Typography>
                <span>{disclosureFields?.repayments?.toLocaleString("en-US", dollarStringOptions) || "0"}/Mth</span>
              </Typography>

              <Typography
                sx={{ display: "inline" }}
                variant="body2"
                style={{
                  marginRight: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body2" fontWeight={"bold"}>
                  Effective Rate:
                </Typography>
                <span>{(1 * disclosureFields?.effectiveRate).toFixed(2)}%</span>
              </Typography>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions style={{ padding: '0 24px 20px 24px' }}>
          <Button onClick={handleCloseDisclosureDialog}>Cancel</Button>
          <Button
            onClick={async () => {
              handleCloseDisclosureDialog();
              await handle.saveQuote();
              await dispatch(
                sendDisclosure({
                  ...disclosureFields,
                  ...{
                    applicationId: application._id,
                    lenderType: productDetails?.lender,
                    effectiveRate: round(disclosureFields?.effectiveRate, 2),
                    rate: +disclosureFields?.rate,
                  },
                }),
              ).unwrap();
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ConnectiveSendDisclosure;